import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect, useCallback } from 'react';
import { localDateFormatToIsoDate } from '@ampli/utils';
import { useExistsCandidateInSameCourse } from '@ampli/services';

var useExistsCandidateInCourse = function useExistsCandidateInCourse(formik, formSettings, candidateAdditionalData) {
  var _existsCandidateInSam, _existsCandidateInSam2;

  var _useState = useState(formik.values.document),
      _useState2 = _slicedToArray(_useState, 2),
      formikDocument = _useState2[0],
      setFormikDocument = _useState2[1];

  var _useState3 = useState(formik.values.birthDate),
      _useState4 = _slicedToArray(_useState3, 2),
      formikBirthDate = _useState4[0],
      setFormikBirthDate = _useState4[1];

  var _useState5 = useState({
    token: '',
    origin: ''
  }),
      _useState6 = _slicedToArray(_useState5, 2),
      captcha = _useState6[0],
      setCaptcha = _useState6[1];

  var handleCaptcha = useCallback(function () {
    var _window;

    var grecaptcha = (_window = window) === null || _window === void 0 ? void 0 : _window.grecaptcha;

    if (grecaptcha) {
      grecaptcha.ready(function () {
        grecaptcha.execute(formSettings.recaptchaSiteKey, {
          action: 'getCandidateToken'
        }).then(function (token) {
          return setCaptcha({
            token: token,
            origin: 'web'
          });
        }).catch(console.error());
      });
    } else {
      throw new Error("Google reCaptcha doesn't exist.");
    }
  }, [setCaptcha, formSettings.recaptchaSiteKey]);
  var regexDocument = formik.values.document.replace(/[^\d]+/g, '');
  var regexBirthDate = localDateFormatToIsoDate(formik.values.birthDate);
  var documentLength = 11;
  var birthDateLength = 10;
  useEffect(function () {
    if (regexDocument.length === documentLength) setFormikDocument(regexDocument);
    if (regexBirthDate.length === birthDateLength) setFormikBirthDate(regexBirthDate);
  }, [formik.values.document, formik.values.birthDate, regexBirthDate, regexDocument]);
  useEffect(function () {
    if (formikDocument && formikBirthDate) handleCaptcha();
  }, [formikDocument, formikBirthDate, handleCaptcha]);

  var _useExistsCandidateIn = useExistsCandidateInSameCourse(formikDocument, candidateAdditionalData.secondStepData.courseId, formikBirthDate, captcha),
      existsCandidateInSameCourse = _useExistsCandidateIn.data;

  var candidateAlreadyRegisteredInCourse = existsCandidateInSameCourse === null || existsCandidateInSameCourse === void 0 ? void 0 : (_existsCandidateInSam = existsCandidateInSameCourse.data) === null || _existsCandidateInSam === void 0 ? void 0 : _existsCandidateInSam.exists;
  var candidateId = existsCandidateInSameCourse === null || existsCandidateInSameCourse === void 0 ? void 0 : (_existsCandidateInSam2 = existsCandidateInSameCourse.data) === null || _existsCandidateInSam2 === void 0 ? void 0 : _existsCandidateInSam2.candidateId;
  var credentialsCandidateRegistered = {
    username: formikDocument,
    password: formikBirthDate
  };
  return {
    candidateAlreadyRegisteredInCourse: candidateAlreadyRegisteredInCourse,
    candidateId: candidateId,
    credentialsCandidateRegistered: credentialsCandidateRegistered
  };
};

export default useExistsCandidateInCourse;