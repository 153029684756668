import React from 'react';
import { cx, css, px2grid, mediaQuery } from '@ampli/ui';
import { string } from '@ampli/utils';

const CNPJInformation = ({ className, ...props }) => (
  <div
    className={cx(
      'cnpj-information',
      css`
        background: #0000001a;
        width: 100%;
        text-align: center;

        ${mediaQuery('mobile')} {
          padding: 0 ${px2grid(14)};
        }
      `,
      className
    )}
    {...props}
  >
    Anhanguera Educacional Participações S/A - CNPJ: 04.310.392/0157-63
  </div>
);

CNPJInformation.propTypes = {
  className: string,
};

export default CNPJInformation;
