import { css, px2grid, px2rem } from '@ampli/ui';

const degreeCard = css`
  flex: 1;

  & {
    .illustration {
      position: absolute;
      bottom: 0;
      left: ${px2grid(8)};
      max-width: ${px2grid(100)};
    }

    .card-content {
      height: 100%;
    }

    .title-wrapper {
      text-align: right;
    }

    .title {
      font-weight: 100;
    }

    .subtitle {
      font-weight: 200;
    }

    .description {
      text-align: right;
    }
  }
`;

const degreeCardCourses = css`
  flex: 1;

  & {
    .illustration {
      max-width: ${px2grid(120)};
    }
    .subtitle {
      font-weight: bold !important;
    }
  }
`;

const minWidthForCardsInRow = px2rem(1024);

const cardWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: fit-content;

  @media (min-width: ${minWidthForCardsInRow}) {
    flex-direction: row;
  }

  & > .degree-card:not(:last-child) {
    margin-bottom: ${px2grid(20)};

    @media screen and (min-width: ${minWidthForCardsInRow}) {
      margin-bottom: 0;
    }
  }
`;

const cardWrapperContent = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${minWidthForCardsInRow}) {
    margin: 0;
    flex-direction: row;
  }

  @media (min-width: ${minWidthForCardsInRow}) {
    flex-direction: row;
    margin: 0;
  }

  & > .degree-card:not(:last-child) {
    margin: 4% 0;

    @media (min-width: ${minWidthForCardsInRow}) {
      margin: 0 4% 4% 0;
    }
  }
`;

export default {
  degreeCard,
  degreeCardCourses,
  cardWrapper,
  cardWrapperContent,
};
