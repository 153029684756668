var duration = 0.5;
var max_messages = 5;

var close = function close(elem) {
  elem.className = 'business-error';
  setTimeout(function () {
    elem.remove();
  }, duration * 1000);
};

var createCloseButton = function createCloseButton(elem) {
  var closeBtn = document.createElement('a');
  closeBtn.className = 'close-button';
  closeBtn.appendChild(document.createTextNode('X'));

  closeBtn.onclick = function () {
    close(elem);
  };

  elem.appendChild(closeBtn);
};

var createMessage = function createMessage(elem, message) {
  var container = document.createElement('div');
  container.className = 'message';
  container.appendChild(document.createTextNode(message));
  elem.appendChild(container);
};

var restrain = function restrain(wrapper) {
  if (wrapper.children.length < max_messages) {
    return;
  }

  var overflow = wrapper.children.length - max_messages + 1;
  var toRemove = [];

  for (var i = 0; i < overflow; i++) {
    toRemove.push(wrapper.children[i]);
  }

  toRemove.forEach(function (value) {
    return close(value);
  });
};

export var createCss = function createCss() {
  var style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = '.business-error { background-color: red; color: white; padding: 20px 40px; border-radius: 5px; font-weight: bold; margin-bottom: 10px; opacity: 0; position: relative; } \n' + ".business-error { -webkit-transition: opacity ".concat(duration, "s ease-in; -moz-transition: opacity ").concat(duration, "s ease-in; -ms-transition: opacity ").concat(duration, "s ease-in; -o-transition: opacity ").concat(duration, "s ease-in; transition: opacity ").concat(duration, "s ease-in; } \n") + '.business-error-wrapper { position: fixed; bottom: 0; left: 50%; transform: translate(-50%, -0%); z-index: 9999; } \n' + '.business-error-loaded { opacity: 1;} \n' + '.business-error > .close-button { position: absolute; top: 0px; right: 2px; font-size: 13px; padding: 5px;} \n';
  document.getElementsByTagName('head')[0].appendChild(style);
  var container = document.createElement('div');
  container.className = 'business-error-wrapper';
  document.getElementsByTagName('body')[0].appendChild(container);
};
export var showError = function showError(message) {
  if (window.errorNotifier) {
    window.errorNotifier(message);
    return;
  }

  try {
    var container = document.createElement('div');
    container.className = 'business-error';
    createCloseButton(container);
    createMessage(container, message);
    var wrapper = document.getElementsByClassName('business-error-wrapper')[0];
    restrain(wrapper);
    wrapper.appendChild(container);
    setTimeout(function () {
      container.className += ' business-error-loaded';
      setTimeout(function () {
        close(container);
      }, 3000);
    }, 100);
  } catch (e) {
    console.error(e);
    alert(message);
  }
};