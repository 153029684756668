import * as Yup from 'yup';
var requiredMsg = 'Preenchimento obrigatório.';
var invalidEmailMsg = 'E-mail inválido.';
var invalidPhoneMsg = 'Telefone inválido.';
var fullnameMsg = 'Informe seu nome e sobrenome.';
export var createEmailLeadSchema = function createEmailLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg)
  });
};
export var createLeadByNameSchema = function createLeadByNameSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, invalidPhoneMsg),
    name: Yup.string().required(requiredMsg)
  });
};
export var createLeadSchema = function createLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    name: Yup.string().notRequired().matches(/[a-z]{3,}\s([a-z]+)+/i, fullnameMsg),
    phone: Yup.string().required(requiredMsg).matches(/\(\d\d\)\s9\d{4}-\d{4}/, invalidPhoneMsg),
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg),
    optIn: Yup.boolean().notRequired()
  });
};
export var updateLeadSchema = function updateLeadSchema() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Yup.object({
    id: Yup.string().required(requiredMsg),
    name: Yup.string().required(requiredMsg).matches(/[a-z]{3,}\s([a-z]+)+/i, fullnameMsg),
    phone: Yup.string().required(requiredMsg),
    email: Yup.string().required(requiredMsg).email(invalidEmailMsg),
    optIn: Yup.boolean().notRequired(),
    courseId: Yup.string().notRequired(),
    postalCode: Yup.string(),
    cityId: Yup.number(),
    stateId: Yup.number()
  });
};