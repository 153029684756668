/* eslint-disable sonarjs/no-duplicate-string */
import { loginUrl, followSubscriptionUrl } from './config';

export const STORES = {
  GOOGLEPLAY: {
    URL: 'https://play.google.com/store/apps/details?id=br.com.ampli',
  },
  APPLESTORE: {
    URL: 'https://apps.apple.com/us/app/id1503246413',
  },
};

export const CONTACT = {
  WHATSAPP: 'https://wa.me/5511991148762',
};

export const HEADER_LINKS = {
  login: loginUrl,
  followSubscription: followSubscriptionUrl,
  cursosLivres: 'https://cursolivre.ampli.com.br/',
  cursoTecnico: 'https://cogna.ubpages.com/ensino-tecnico/',
  FAQ: 'https://duvidas.ampli.com.br/',
  blog: 'https://blog.ampli.com.br/',
};

export const COURSE_DURATION_VALUES = {
  '5-meses': 5,
  '6-meses': 6,
  '7-meses': 7,
  '8-meses': 8,
  '9-meses': 9,
  '1-ano': 12,
  '1-ano-e-meio': 18,
  '2-anos': 24,
  '2-anos-e-meio': 30,
  '3-anos': 36,
};

export const SUBJECT_LIST_DIVISION_TYPES = {
  SEMESTER: 'SEMESTER',
  LIST: 'LIST',
};

export const COURSE_DEGREE_TYPES = {
  UNDERGRADUATE: 'UNDERGRADUATE',
  GRADUATE: 'GRADUATE',
  EJA: 'EJA',
};

const { UNDERGRADUATE, GRADUATE, EJA } = COURSE_DEGREE_TYPES;

export const CONFIG_BY_DEGREE_TYPE = {
  PATHNAME: {
    [UNDERGRADUATE]: { label: 'Graduação', pathname: '/graduacao' },
    [GRADUATE]: { label: 'Pós-graduação', pathname: '/pos-graduacao' },
    [EJA]: { label: 'EJA', pathname: '/eja' },
  },
  COURSE_LIST_FILTERS: {
    [UNDERGRADUATE]: [
      {
        name: 'courseDuration',
        items: [
          { label: '1 ano e meio', value: '1-ano-e-meio' },
          { label: '2 anos', value: '2-anos' },
          { label: '2 anos e meio', value: '2-anos-e-meio' },
          { label: '3 anos', value: '3-anos' },
        ],
        type: 'groupButtons',
        label: 'Tempo de formação',
        extraInfo: 'em até',
      },
      {
        name: 'fieldOfStudy',
        items: [],
        type: 'checkbox',
        label: 'Área de atuação',
        wrap: true,
      },
      {
        name: 'courseType',
        items: [
          { label: 'Tecnólogo', value: 'tecnologo' },
          { label: 'Bacharelado', value: 'bacharelado' },
          { label: 'Licenciatura', value: 'licenciatura' },
        ],
        type: 'checkbox',
        label: 'Tipos de graduação',
      },
    ],
    [GRADUATE]: [
      {
        name: 'courseDuration',
        items: [
          { label: '5 meses', value: '5-meses' },
          { label: '6 meses', value: '6-meses' },
          { label: '7 meses', value: '7-meses' },
          { label: '8 meses', value: '8-meses' },
          { label: '9 meses', value: '9-meses' },
        ],
        type: 'groupButtons',
        label: 'Conclusão do curso',
        extraInfo: 'em até',
      },
      {
        name: 'fieldOfStudy',
        items: [],
        type: 'checkbox',
        label: 'Área de atuação',
        wrap: true,
      },
      {
        name: 'courseType',
        items: [
          { label: 'Especialização', value: 'pos-graduacao' },
          { label: 'MBA', value: 'mba' },
        ],
        type: 'checkbox',
        label: 'Tipos de pós-graduação',
      },
    ],
    [EJA]: [
      {
        name: 'courseDuration',
        items: [
          { label: '1 ano e meio', value: '1-ano-e-meio' },
          { label: '2 anos', value: '2-anos' },
        ],
        type: 'groupButtons',
        label: 'Conclusão do curso',
        extraInfo: 'em até',
      },
    ],
  },
  MIN_PRICES: {
    [UNDERGRADUATE]: 149.99,
    [GRADUATE]: 210.0,
    [EJA]: 99.9,
  },
  DURATION_TYPES: {
    [UNDERGRADUATE]: 'months',
    [GRADUATE]: 'months',
    [EJA]: 'months',
  },
  METADATA: {
    defaultTitle: {
      [UNDERGRADUATE]: 'Cursos de Graduação EAD | Ampli',
      [GRADUATE]: 'Cursos de Pós-graduação EAD | Ampli',
      [EJA]: 'Educação para Jovens e Adultos (EJA) - Supletivo | Ampli',
    },
    filterMetaInformation: {
      [UNDERGRADUATE]: {
        title: 'Graduação',
        courseType: 'graduação',
        minTime: '1 ano e meio',
        brandText:
          ' da Ampli. São diversas opções, com polos EAD espalhados por todo o Brasil.',
      },
      [GRADUATE]: {
        title: 'Pós-graduação',
        courseType: 'pós-graduação',
        minTime: '5 meses',
        brandText: ' da Ampli. Saiba mais.',
      },
      [EJA]: {
        title: 'Ensino de Jovens e Adultos',
        courseType: 'EJA',
        minTime: '1 ano e meio',
        brandText: ' da Ampli. Saiba mais.',
      },
    },
    defaultDescription: {
      [UNDERGRADUATE]:
        'Cursos de graduação a distância (EAD). Licenciatura, Bacharelado e Tecnólogo em diversas áreas. Cursos de 1 ano e meio até 3 anos de duração. Inscreva-se!',
      [GRADUATE]:
        'Cursos de pós-graduação a distância. Especialização e MBA online em diversas áreas. Cursos de 5 meses até 9 meses de duração. Faça a sua matrícula!',
      [EJA]:
        'Conheça os módulos de ensino da Educação de Jovens e Adultos (EJA). Conclua o Ensino Fundamental e Ensino Médio estudando pelo seu celular. Saiba mais!',
    },
  },
  COURSE_LIST_BANNER_DESCRIPTION: {
    [UNDERGRADUATE]: { title: 'Graduação', lowerCase: true },
    [GRADUATE]: { title: 'Pós-Graduação', lowerCase: true },
    [EJA]: { title: 'EJA' },
  },
  SUBJECT_LIST_DIVISION: {
    [UNDERGRADUATE]: SUBJECT_LIST_DIVISION_TYPES.SEMESTER,
    [GRADUATE]: SUBJECT_LIST_DIVISION_TYPES.LIST,
    [EJA]: SUBJECT_LIST_DIVISION_TYPES.LIST,
  },
};

export const {
  PATHNAME: PATHNAME_BY_DEGREE_TYPE,
  COURSE_LIST_FILTERS: COURSES_LIST_FILTERS_BY_DEGREE_TYPE,
  DURATION_TYPES: COURSE_DEGREE_DURATION_TYPES,
  MIN_PRICES,
  METADATA,
  COURSE_LIST_BANNER_DESCRIPTION,
  SUBJECT_LIST_DIVISION,
} = CONFIG_BY_DEGREE_TYPE;

export const COURSE_FILTERS_TO_URL_PARAMS = {
  name: 'nome',
  courseDuration: 'duracao',
  fieldOfStudy: 'area',
  courseType: 'tipo',
  page: 'pagina',
};

export const ARRAY_URL_PARAMS = ['fieldOfStudy', 'courseType'];

export const SCROLLER_SETTINGS = {
  isInViewportSettings: {
    modBottom: '-70%',
  },
};

export const BENEFITS_TOOLTIP_TEXTS = {
  '1º mês grátis':
    'Faça sua matrícula e o primeiro mês de aula é por nossa conta. Você não paga nada! Se você não gostar do curso, é possível cancelar a matrícula dentro desse período sem pagar nenhuma multa.',
  'Forme mais rápido':
    'Aqui permitimos que o aluno emende um módulo no outro. Dessa forma, você pode antecipar suas aulas no período de férias (janeiro, julho e dezembro) e se formar mais rápido.',
  'Valor único':
    'Nossas mensalidades são fixas, reajustadas anualmente apenas pela inflação.',
  'Aula pelo celular':
    'Tenha a praticidade de estudar através do nosso aplicativo, quando quiser, em qualquer lugar. Você ainda tem a opção de utilizar o Wi-Fi para fazer download das aulas para estudar off-line.',
};

export const EJACourseLevelURLCodes = {
  HIGHSCHOOL: 'eja-ensino-medio',
  MIDDLESCHOOL: 'eja-ensino-fundamental',
  NINTHGRADEMIDDLESCHOOL: 'eja-ensino-fundamental---9-ano',
  THIRDGRADEHIGHSCHOOL: 'eja-ensino-medio---3-ano',
};

export const EJASharedURLSearchParams = [
  { key: 'sellerId', value: '33e04435-1b9c-412c-9ff4-cae019a08576' },
  { key: 'utm_campaign', value: 'eja' },
  { key: 'utm_medium', value: 'share-button' },
];

export const TIM_BLACK_FRIDAY_START_DATE = new Date(
  Date.UTC(2021, 9, 24, 3, 0, 0, 0)
);

export const TIM_BLACK_FRIDAY_END_DATE = new Date(
  Date.UTC(2021, 11, 1, 3, 0, 0, 0)
);

//TODO: change for Promo dates
export const TIM_PROMO_START_DATE = new Date(Date.UTC(2022, 5, 1, 3, 0, 0, 0));

export const TIM_PROMO_END_DATE = new Date(Date.UTC(2022, 9, 31, 23, 0, 0, 0));

export const BF_DISCOUNT_PERCENTAGE_TEXT = '13% OFF';

export const AMPLI_BLACK_FRIDAY_START_DATE = new Date(
  Date.UTC(2021, 9, 26, 3, 0, 0, 0)
);

export const AMPLI_BLACK_FRIDAY_END_DATE = new Date(
  Date.UTC(2021, 11, 1, 3, 0, 0, 0)
);

export const FOOTER_SEO_CATEGORIES = [
  {
    title: 'Institucional',
    items: [
      {
        label: 'Cogna Educação',
        url: 'http://www.cogna.com.br/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Trabalhe Conosco',
        url: 'https://jobs.kenoby.com/ampli',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Termos de Uso',
        url: 'https://ofertas.ampli.com.br/termos-de-uso',
      },
      {
        label: 'Política de Privacidade',
        url: 'https://www.canaldatransparencia.com.br/cogna/',
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        label: 'Polos EAD',
        url: '/polos-ead',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Cursos',
    items: [
      {
        label: 'Graduação',
        url: '/graduacao',
        isRelative: true,
      },
      {
        label: 'Pós-graduação',
        url: '/pos-graduacao',
        isRelative: true,
      },
      {
        label: 'Cursos Livres',
        url: 'https://cursolivre.ampli.com.br/',
      },
      {
        label: 'Curso Técnico',
        url: 'https://cogna.ubpages.com/ensino-tecnico/',
      },
      {
        label: 'EJA',
        url: '/eja',
        isRelative: true,
      },
    ],
  },
  {
    title: 'Estude com a gente',
    items: [
      {
        label: 'Processo Seletivo',
        url: 'https://ampli.com.br/processo-seletivo/login',
      },
      {
        label: 'AVA Ampli',
        url: 'https://ava.ampli.com.br/login',
      },
    ],
  },
  {
    title: 'Informações',
    items: [
      {
        label: 'Dúvidas Frequentes',
        url: 'https://duvidas.ampli.com.br/hc/pt-br',
      },
      {
        label: 'Parceria TIM',
        url: 'https://www.ampli.com.br/parceria/tim',
      },
    ],
  },
];

export const EJACoursesIds = {
  highSchoolCourseId: '65c89bf3-98f9-4f1c-bacb-18a02a237c05',
  elementarySchoolCourseId: '63981b5e-2cae-4b5d-90b0-e3b512cf6df1',
  ninthGradeElementarySchoolCourseId: 'ae0892c4-95c7-4edc-8073-c4a1f2f6b474',
  thirdGradeHighSchoolCourseId: '17124c50-51ec-4400-8d11-9d245d734619',
};

export const FREE_TRIAL_TOTAL_DAYS = 15;

export const EJA_FREE_TRIAL_TOTAL_DAYS = 7;
