import { calculateProgress } from '@ampli/utils';

var readPercentage = function readPercentage(cached, _ref) {
  var readField = _ref.readField;
  if (cached) return cached;

  var _calculateProgress = calculateProgress(readField('completed') || 0, readField('quantity')),
      percentage = _calculateProgress.percentage;

  return percentage;
};

var percentage = {
  read: readPercentage
};
var percentageLabel = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var percentage = readPercentage(null, {
      readField: readField
    });
    var percentageValue = percentage > 1 ? 1 : percentage;
    return "".concat(Math.round(percentageValue * 100), "%");
  }
};
export default {
  fields: {
    percentage: percentage,
    percentageLabel: percentageLabel
  }
};