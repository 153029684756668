import React, { FunctionComponent, ReactNode } from 'react';
import { cx, Text, Flex } from '@ampli/ui';
import { Quote } from '@ampli/icon';
import styles from './styles';

interface StatementProps {
  className?: string;
  color?: string;
  children: ReactNode;
}

const Statement: FunctionComponent<StatementProps> = ({
  children,
  className,
  color = 'neutral-title',
  ...props
}: StatementProps) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  React.useEffect(() => {
    setIsUpdating(true);
    setTimeout(() => setIsUpdating(false), 400);
  }, [children]);

  return (
    <Flex direction="row" justifyContent="center" alignItems="flex-start">
      <div
        className={cx(
          'statement',
          styles.statement,
          isUpdating ? 'inactive' : 'active',
          className
        )}
        {...props}
      >
        <Quote className={cx('quote-mark', styles.quoteMark)} />
        <Text
          as="p"
          color={color}
          className={cx(
            'statement-text',
            styles.statementText,
            isUpdating ? 'inactive' : 'active'
          )}
        >
          {!isUpdating && children}
        </Text>
        <Quote className={cx('quote-mark', styles.quoteMark)} />
      </div>
    </Flex>
  );
};

export default Statement;
