import { px2rem, css, mediaQuery as _mediaQuery } from '@ampli/ui';
import { Viewport } from '../types';

enum Commands {
  MAX_WIDTH = 'max-width',
  MIN_WIDTH = 'min-width',
}

export enum HideModes {
  DISPLAY_NONE = 'DISPLAY_NONE',
  ZERO_HEIGHT = 'ZERO_HEIGHT',
}

function hide(
  width: Viewport | number,
  command?: Commands,
  mode: HideModes = HideModes.DISPLAY_NONE
): string {
  const mediaQuery =
    typeof width === 'string'
      ? _mediaQuery(width)
      : `@media (${command}: ${px2rem(width)})`;

  const hideCSS = {
    DISPLAY_NONE: css`
      ${mediaQuery} {
        display: none;
      }
    `,
    ZERO_HEIGHT: css`
      ${mediaQuery} {
        display: block;
        position: absolute;
        overflow: hidden;
        width: 0;
        height: 0;
        flex: 0;
      }
    `,
  };

  return hideCSS[mode];
}

export function hideOnMaxWidth(width: number, mode?: HideModes): string {
  return hide(width, Commands.MAX_WIDTH, mode);
}

export function hideOnMinWidth(width: number, mode?: HideModes): string {
  return hide(width, Commands.MIN_WIDTH, mode);
}

export const hideOnDesktop = hide('desktop');
export const hideOnMobile = hide('mobile');
export const hideOnTablet = hide('tablet');
