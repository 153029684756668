import * as React from 'react';

function SvgRunningGirl(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={176}
      height="157pt"
      viewBox="0 0 132 260"
      {...props}
    >
      <g clipPath="url(#clip0_13918_36220)">
        <path
          d="M76.9254 27.2557C76.9254 27.2557 77.5762 23.8737 76.1348 21.9036C74.6935 19.9334 70.9536 19.2259 69.2107 21.9036C69.2107 21.9036 64.6612 23.823 65.0422 27.4334C65.42 31.0438 70.1218 34.4131 70.3726 35.1523C70.6266 35.8915 71.8648 36.7767 72.9251 36.0184C73.9887 35.2602 78.0207 29.099 76.9254 27.2557Z"
          fill="#311E69"
        />
        <path
          d="M100.991 100.615C101.365 101.951 105.737 137.195 94.6253 224.775C94.2761 227.535 86.409 223.699 84.0788 218.74C77.8086 205.403 73.31 176.929 79.6785 151.272C83.1009 137.487 80.7802 121.088 81.7167 114.565C82.5454 108.779 85.0312 97.4778 88.0218 91.8782C90.1045 87.9759 98.9684 93.3978 100.991 100.615Z"
          fill="#FF6161"
        />
        <path
          d="M49.3593 104.299C50.788 99.0291 54.7215 94.0894 58.6424 90.3204C60.3472 88.6833 72.3161 89.6795 82.869 91.5704C83.8278 97.8426 82.742 108.902 82.0436 114.708C81.9388 114.648 81.8309 114.6 81.7166 114.565C79.3133 113.867 83.1166 138.664 69.1096 167.11C61.0488 183.477 5.18243 181.247 4.43001 173.683C3.72521 166.602 49.045 158.953 49.045 158.953C49.045 158.953 47.4545 111.32 49.3593 104.299Z"
          fill="#FF6161"
        />
        <path
          d="M70.1697 154.486C64.4425 146.945 58.9565 167.877 49.4068 166.799C39.857 165.72 38.0093 171.726 35.0377 173.572C32.0662 175.419 21.6815 176.034 14.443 173.572C9.53166 171.9 7.38869 174.261 6.51562 175.952C16.8209 181.51 61.9312 181.688 69.1094 167.107C70.1761 164.936 71.1412 162.789 72.0111 160.673C71.9349 158.277 71.4047 156.11 70.1697 154.486Z"
          fill="#AD263D"
        />
        <path
          d="M101.984 114.166C97.8373 119.194 89.4242 130.511 89.3702 139.559C89.3004 151.564 89.4401 172.96 89.3702 178.963C89.3004 184.965 85.0811 189.096 86.605 199.28C89.3702 217.751 94.4657 226.018 94.6245 224.775C101.999 166.634 102.555 131.567 101.984 114.166Z"
          fill="#AD263D"
        />
        <path
          d="M79.9766 38.5723C79.9766 38.5723 107.134 34.4162 109.076 30.7455C112.388 24.4987 100.987 11.1072 104.879 5.4124C107.826 1.10085 125.096 23.7785 121.515 35.0856C117.934 46.3927 91.7105 50.8946 87.0912 49.6256C82.4688 48.3597 79.9766 38.5723 79.9766 38.5723Z"
          fill="#FF6161"
        />
        <path
          d="M87.091 49.6289C85.91 49.3052 84.8718 48.4233 83.9766 47.2938C85.9957 45.6282 88.3228 44.1879 90.5198 43.2773C96.8344 40.6599 103.457 42.9696 109.619 41.8148C115.781 40.6599 115.702 38.5058 116.937 35.5521C118.169 32.5984 118.093 29.2704 118.245 23.804C118.401 18.3408 115.165 17.2621 113.854 14.8763C113.318 13.8992 113.223 12.4398 113.296 11.0597C118.48 17.7665 123.633 28.4074 121.518 35.0825C117.934 46.396 91.7103 50.8979 87.091 49.6289Z"
          fill="#AD263D"
        />
        <path
          d="M82.6875 41.1357L84.5924 48.0012C85.3384 48.7817 86.1734 49.3749 87.0909 49.6256C88.5672 50.0317 92.2499 49.8477 96.6914 49.0418C93.4246 44.2036 82.6875 41.1357 82.6875 41.1357Z"
          fill="#311E69"
        />
        <path
          d="M47.6463 116.57C46.7923 107.078 49.7385 99.2798 52.4529 94.5685C54.6911 90.6789 67.7204 68.585 68.2474 66.6909C68.5808 64.8001 68.3839 62.8077 68.0379 60.9327C67.4505 57.7442 63.4694 45.7614 64.0091 42.9378C64.5361 40.1776 73.2223 35.2918 81.8386 39.2988C84.3213 40.4536 87.7183 43.5977 88.7532 44.6034C88.7532 44.6034 89.9978 45.7963 90.3946 46.5291C90.8645 47.4016 91.128 50.5647 89.6263 55.13C89.1945 56.4467 90.9375 60.479 92.528 67.5317C98.4871 87.0431 108.075 97.7062 100.713 163.607C99.2871 164.02 66.1076 162.335 49.0432 158.947C47.4051 144.292 48.2178 122.909 47.6463 116.57Z"
          fill="#614CD4"
        />
        <path
          d="M92.5249 67.538C92.0296 65.3363 91.5185 63.4295 91.0613 61.8083C87.55 66.8179 82.6704 75.6694 85.1626 83.5406C88.8898 95.3109 99.5475 126.069 96.687 141.637C95.2584 149.41 92.6328 156.612 90.9312 163.366C96.4362 163.661 100.246 163.747 100.706 163.614C108.072 97.7094 98.4839 87.0495 92.5249 67.538Z"
          fill="#311E69"
        />
        <path
          d="M94.5656 73.972C93.8513 71.7607 93.1656 69.6256 92.5274 67.5349C90.9369 60.4822 89.1939 56.4498 89.6257 55.1332C91.1274 50.5679 90.8639 47.4048 90.394 46.5323C89.9971 45.7963 88.7558 44.6066 88.7526 44.6066C87.7177 43.6009 84.3175 40.4568 81.838 39.302C73.2248 35.295 64.5355 40.1808 64.0085 42.941C63.472 45.7614 67.4499 57.7474 68.0373 60.9359C68.3833 62.8109 68.5802 64.8033 68.2468 66.6941C68.0404 67.427 65.9641 71.1833 63.3672 75.7392C66.2054 76.545 82.6253 80.755 94.5656 73.972Z"
          fill="#FF8F8F"
        />
        <path
          d="M107.385 7.57301C108.036 7.29382 108.277 6.50067 107.89 5.91057C106.76 4.18151 104.213 0.612349 102.347 0.336334C99.8767 -0.0285137 98.9338 -0.241077 99.4005 0.501309C99.864 1.24052 101.667 1.73227 102.432 2.54128C103.198 3.35029 102.585 3.71197 102.21 3.59776C101.836 3.48354 100.62 2.56032 100.112 3.00448C99.6037 3.44864 100.035 3.37567 100.918 4.54636C101.801 5.71387 103.956 8.05207 105.82 7.93151C106.468 7.8871 106.985 7.74433 107.385 7.57301Z"
          fill="#FF6161"
        />
        <path
          d="M92.5255 67.5381C90.9349 60.4854 89.1919 56.453 89.6237 55.1364C91.1254 50.5711 90.8619 47.408 90.392 46.5355C89.9952 45.7995 88.7538 44.6098 88.7506 44.6098C87.7157 43.6041 84.3155 40.46 81.836 39.3052C79.5089 38.2233 77.1818 37.8046 75.0039 37.8014C77.5056 40.0159 81.7249 43.9975 84.5917 48.0044C88.8903 54.0165 85.1854 54.4638 81.1947 55.5679C77.204 56.6719 79.3343 59.1022 82.6868 59.9746C85.0108 60.5806 88.1474 69.099 89.7444 76.0787C91.3984 75.5393 93.0175 74.8477 94.5605 73.9721C93.8493 71.7608 93.1636 69.6256 92.5255 67.5381Z"
          fill="#D95F76"
        />
        <path
          d="M93.0467 69.2005C92.8721 68.6453 92.6943 68.0837 92.526 67.5349C92.3959 66.9606 92.2657 66.4118 92.1355 65.8788C84.1986 69.4004 72.6742 68.9657 67.3819 68.5216C66.9247 69.3908 66.3152 70.5076 65.5977 71.7925C67.3946 71.986 70.5725 72.2525 74.3632 72.2525C80.1095 72.2525 87.2464 71.6339 93.0467 69.2005Z"
          fill="#FFE6E6"
        />
        <path
          d="M90.9775 61.5038C90.6156 60.2189 90.2918 59.1212 90.0473 58.2043C83.0692 61.047 73.6528 61.0533 67.9922 60.717C68.0049 60.7868 68.0271 60.8725 68.0398 60.9359C68.2335 61.9924 68.37 63.087 68.4017 64.1815C69.7542 64.2545 71.2812 64.3052 72.9258 64.3052C78.4657 64.3052 85.2978 63.7215 90.9775 61.5038Z"
          fill="#FFE6E6"
        />
        <path
          d="M65.8555 52.7411C66.1761 53.9244 66.5031 55.0856 66.8047 56.1706C68.3826 56.1579 70.2589 56.1008 72.3098 55.9517C77.964 55.5425 84.9231 54.432 90.4917 51.6719C90.7869 49.9587 90.806 48.5913 90.6853 47.6649C83.4119 52.1446 71.7447 52.7506 65.8555 52.7411Z"
          fill="#FFE6E6"
        />
        <path
          d="M64.0508 44.7684C64.1682 45.7265 64.3968 46.9004 64.6953 48.1948C66.0922 48.1726 67.6764 48.1123 69.3876 47.9886C75.0894 47.573 82.1184 46.4499 87.7123 43.6389C86.9377 42.9346 85.9122 42.0431 84.8296 41.2183C78.1341 44.1085 69.378 44.7081 64.0508 44.7684Z"
          fill="#FFE6E6"
        />
        <path
          d="M69.0165 45.9994L65.375 50.9455C66.2639 54.3433 67.3275 57.9156 67.8259 59.9746C73.7501 56.3008 69.0165 45.9994 69.0165 45.9994Z"
          fill="#311E69"
        />
        <path
          d="M65.2914 41.3927C56.3767 46.0501 40.7949 60.5933 37.4297 58.8642C33.385 56.7861 33.185 35.092 27.391 37.1637C21.9209 39.118 22.2955 69.486 33.9628 73.3566C49.4938 78.512 66.5455 55.7011 67.7963 52.6301C70.3393 46.3959 68.6757 39.6224 65.2914 41.3927Z"
          fill="#FF6161"
        />
        <path
          d="M33.9655 73.3565C24.2158 70.1205 22.3553 48.3946 25.1936 40.1935C29.8573 36.5735 28.2255 43.4327 28.6128 46.434C28.997 49.4352 28.3049 57.0558 29.6129 61.5958C30.9209 66.1357 32.1559 67.6744 35.1592 69.6763C38.1625 71.6782 41.242 69.7525 46.0963 65.1237C50.9473 60.4949 50.0996 64.597 54.7221 62.2112C59.3414 59.8255 62.0399 54.5907 62.9638 50.2823C63.7607 46.5704 67.7291 44.6859 68.8149 44.2353C69.2721 46.4752 69.0276 49.6129 67.799 52.63C66.5449 55.6979 49.4964 78.5088 33.9655 73.3565Z"
          fill="#AD263D"
        />
        <path
          d="M29.7828 35.4378C29.2685 33.3629 29.878 32.8204 29.5479 32.1098C29.2177 31.3991 28.2018 31.9353 27.8398 33.5469C27.4779 35.1586 26.9414 34.9968 26.2842 34.7843C25.627 34.5717 22.7443 31.7544 21.3157 32.8236C19.887 33.8928 21.6522 35.3109 22.9793 37.1764C24.3063 39.0419 25.954 42.1574 28.1541 41.1865C31.5988 39.67 30.1098 36.7639 29.7828 35.4378Z"
          fill="#FF6161"
        />
        <path
          d="M74.0947 30.7456L75.4472 36.1739C75.7393 37.3636 80.8602 40.5394 77.6441 42.4429C76.4441 43.1536 71.4121 39.724 71.6406 38.363L71.5898 31.8084L74.0947 30.7456Z"
          fill="#FF6161"
        />
        <path
          d="M74.8764 33.8833L75.4479 36.1739C75.4574 36.212 75.4701 36.2501 75.4892 36.2913C74.5812 36.0978 73.4542 35.6917 73.3049 34.8636C73.1843 34.191 73.9939 33.9499 74.8764 33.8833Z"
          fill="#311E69"
        />
        <path
          d="M70.678 30.6599C70.5574 32.9473 72.2305 35.0317 74.5163 35.1998C76.6275 35.3553 78.507 33.953 78.5419 31.3039C78.5736 28.9498 76.7132 27.1097 76.0053 26.1199C74.9481 24.6383 74.0401 24.9841 73.3893 27.0146C72.9988 28.2265 70.678 30.6599 70.678 30.6599Z"
          fill="#FF6161"
        />
        <path
          d="M70.8888 30.8692C70.7142 30.6852 70.5681 30.4758 70.4348 30.2601C70.184 29.8477 69.7141 29.2925 69.2855 29.9682C68.6633 30.9486 70.1237 33.071 71.2443 32.6173C72.0793 32.281 71.3301 31.3356 70.8888 30.8692Z"
          fill="#FF6161"
        />
        <path
          d="M75.5 30.7456C75.5 30.7456 76.535 30.5298 77.789 29.6827C77.789 29.6827 78.0303 30.3807 77.7287 30.8439C77.3668 31.4055 76.3889 31.6783 75.5 30.7456Z"
          fill="#F0F3F7"
        />
        <path
          d="M74.6722 34.2481C75.5262 34.1846 77.0056 33.6929 77.5771 33.1091C78.0818 32.592 78.4596 31.4308 78.539 31.1738C78.539 31.2182 78.5454 31.2595 78.5422 31.3039C78.5073 33.953 76.6278 35.3521 74.5166 35.1998C73.107 35.0983 71.9323 34.2639 71.2656 33.1059C72.5387 34.0767 73.9039 34.3052 74.6722 34.2481Z"
          fill="#AD263D"
        />
        <path
          d="M86.0886 222.151C87.3902 221.983 89.9269 222.535 90.8761 222.18C91.8254 221.824 93.3112 220.305 94.9335 220.612C96.5558 220.92 99.8893 224.299 101.124 225.057C102.359 225.815 105.461 226.409 106.042 228.027C106.061 228.893 105.229 229.613 104.182 229.635L87.7299 229.997C86.2695 230.029 85.025 229.162 84.7964 227.97C84.552 226.688 84.7869 222.322 86.0886 222.151Z"
          fill="#F0F3F7"
        />
        <path
          d="M7.72775 170.606C7.99126 171.891 7.62299 174.461 8.04841 175.381C8.47382 176.301 10.0993 177.671 9.90882 179.308C9.71833 180.949 6.59119 184.518 5.92766 185.806C5.26413 187.094 4.89586 190.225 3.32435 190.923C2.46081 191.006 1.683 190.228 1.5814 189.188L0.0162431 172.817C-0.123447 171.364 0.651197 170.06 1.82586 169.746C3.08624 169.407 7.46425 169.321 7.72775 170.606Z"
          fill="#F0F3F7"
        />
        <path
          d="M81.0076 103.566C81.0076 103.566 72.6484 143.245 68.5625 150.365C68.5625 150.365 77.9662 146.282 81.4806 132.896C84.9951 119.515 81.0076 103.566 81.0076 103.566Z"
          fill="#311E69"
        />
      </g>
      <defs>
        <clipPath id="clip0_13918_36220">
          <rect width="122" height="230" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRunningGirl;
