import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["loading", "data"],
    _excluded2 = ["loading", "data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useQuery } from '../hooks';
import { FIND_AFFILIATE_BY_GEOLOCATION_NEAR, FIND_AFFILIATE_BY_STATUS } from './queries';
export var useFindAffiliatesByGeolocationNear = function useFindAffiliatesByGeolocationNear(latitude, longitude, courseDegree) {
  var distance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  var _useQuery = useQuery(FIND_AFFILIATE_BY_GEOLOCATION_NEAR, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      longitude: longitude,
      latitude: latitude,
      distance: distance,
      courseDegree: courseDegree
    },
    skip: !longitude || !latitude || !distance
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, _excluded);

  var exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading: loading
  }, rest);
};
export var useFindAffiliatesByStatus = function useFindAffiliatesByStatus(courseDegree, status) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery2 = useQuery(FIND_AFFILIATE_BY_STATUS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseDegree: courseDegree,
      status: status
    },
    skip: !courseDegree || !status
  })),
      loading = _useQuery2.loading,
      data = _useQuery2.data,
      rest = _objectWithoutProperties(_useQuery2, _excluded2);

  var exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading: loading
  }, rest);
};