import React from 'react';
import { Link } from 'react-router-dom';

import {
  css,
  cx,
  px2rem,
  Button,
  ColorNeutralDark,
  ColorNeutralLighter,
} from '@ampli/ui';

import { string, bool, node, func } from '@ampli/utils';

const ButtonMenu = ({
  hasBorder = true,
  hasLetterSpacing = true,
  color,
  children,
  onClick,
  ...props
}) => (
  <Button
    {...props}
    as={(onClick && 'button') || (props.target === '_blank' && 'a') || Link}
    {...(onClick && {
      onClick: (e) => {
        e.preventDefault();
        onClick(e);
      },
    })}
    className={cx(
      'menu-mobile-item',
      css`
        color: ${color || ColorNeutralDark};
        font-weight: 400;
        &.has-border {
          border-bottom: 1px solid ${ColorNeutralLighter};
          border-radius: 0px;
        }
        &.has-letter-spacing {
          letter-spacing: ${px2rem(5)};
        }
      `,
      hasBorder && 'has-border',
      hasLetterSpacing && 'has-letter-spacing'
    )}
  >
    {children}
  </Button>
);

ButtonMenu.propTypes = {
  hasBorder: bool,
  hasLetterSpacing: bool,
  color: string,
  children: node,
  onClick: func,
  target: string,
};

export default ButtonMenu;
