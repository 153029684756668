import React from 'react';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { string, bool } from '@ampli/utils';

const UnifiedRedirect = ({ pathname, preserveQuerystrings }) => {
  const { search } = useLocation();

  const detectQueryString = (url) => {
    // regex pattern for detecting querystring
    const pattern = new RegExp(/\?.+=.*/g);
    return pattern.test(url);
  };

  const formatQueryString = (search) => {
    if (search.length > 1) {
      return search.replace(search.charAt(0), '&');
    }
    return '';
  };

  let path = pathname;

  if (preserveQuerystrings) {
    if (detectQueryString(path)) {
      path += formatQueryString(search);
    } else {
      path += search;
    }
  }
  return <Redirect to={path} />;
};

UnifiedRedirect.propTypes = {
  pathname: string,
  preserveQuerystrings: bool,
};

export default UnifiedRedirect;
