export var STORAGE_SUBSCRIPTION_FORM = 'subscriptionForm';
export var DEGREE_TYPES = {
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós-graduação',
  EJA: 'EJA'
};
export var INGRESS_MODALITY = 'IngressModality',
    AFFILIATE = 'Affiliate';
export var SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE = {
  UNDERGRADUATE: [INGRESS_MODALITY, AFFILIATE],
  GRADUATE: [],
  EJA: [AFFILIATE]
};
export var EJACoursesIds = {
  highSchoolCourseId: '65c89bf3-98f9-4f1c-bacb-18a02a237c05',
  elementarySchoolCourseId: '63981b5e-2cae-4b5d-90b0-e3b512cf6df1'
};