import React, { FunctionComponent, KeyboardEvent } from 'react';
import { cx, Flex } from '@ampli/ui';
import styles from './styles';
import { Testimonial } from '../types';

interface HeaderProps {
  className?: string;
  items: Testimonial[];
  selectedElement: number;
  onClickElement: (index: number) => void;
}

const Header: FunctionComponent<HeaderProps> = ({
  items,
  selectedElement,
  onClickElement,
  className,
  ...props
}: HeaderProps) => {
  function handleKeyboardSelection(event: KeyboardEvent, index: number) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      return onClickElement(index);
    }
  }
  return (
    <Flex direction="row" justifyContent="space-around" alignItems="center">
      <div
        className={cx('header', styles.header, className)}
        tabIndex={0}
        {...props}
      >
        {items.map((item, index) => (
          <Flex key={index} justifyContent="center" alignItems="center">
            <div
              id={`header-element-${index}`}
              className={cx(
                'header-element',
                styles.headerElement,
                selectedElement !== index && 'inactive',
                className
              )}
              tabIndex={0}
              onClick={() => onClickElement(index)}
              onKeyDown={(event) => handleKeyboardSelection(event, index)}
            >
              {item.imgUrl && (
                <img
                  alt={item.name}
                  src={item.imgUrl}
                  height={30}
                  loading="lazy"
                />
              )}
            </div>
          </Flex>
        ))}
      </div>
    </Flex>
  );
};

export default Header;
