// should be COMMONS_JS
const squad = process.env.SQUAD_NAME;
const env = 'stg';

module.exports = {
  graphqlUrl: {
    [squad]: `https://graphql.${squad}.${env}.ampli.com.br`,
  },
  subscriptionUrl: {
    [squad]: `https://${squad}.${env}.ampli.com.br`,
  },
  entranceExamUrl: {
    [squad]: `https://${squad}.${env}.ampli.com.br/processo-seletivo`,
  },
  baseUrl: {
    [squad]: `https://${squad}.${env}.ampli.com.br`,
  },
  loginUrl: {
    [squad]: `http://ava.${squad}.${env}.ampli.com.br/login`,
  },
  followSubscriptionUrl: {
    [squad]: `https://${squad}.${env}.ampli.com.br/processo-seletivo/login`,
  },
  avaUrl: {
    [squad]: `https://ava.${squad}.${env}.ampli.com.br`,
  },
  env: 'staging',
};
