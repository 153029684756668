export var calculateProgress = function calculateProgress(completedQuantity, subjectsQuantity) {
  var percentageNumber = 0;

  if (completedQuantity != 0 && subjectsQuantity != 0) {
    percentageNumber = completedQuantity / subjectsQuantity;
    percentageNumber = percentageNumber > 1 ? 1 : percentageNumber;
  }

  var percentageLabel = "".concat(Math.round(percentageNumber * 100), "%");
  return {
    completed: completedQuantity,
    quantity: subjectsQuantity,
    percentage: percentageNumber,
    percentageLabel: percentageLabel
  };
};