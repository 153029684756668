import React from 'react';
import { useHistory } from 'react-router-dom';

import { css, cx, Logo, px2grid } from '@ampli/ui';
import { string } from '@ampli/utils';

const AmpliLogo = ({ redirectOnClick = true, ...props }) => {
  const history = useHistory();

  const logoCss = redirectOnClick
    ? css`
        align-self: center;
        cursor: pointer;
      `
    : css`
        align-self: center;
      `;
  const onClickAction = () => (redirectOnClick ? history.push('/') : null);

  return (
    <div
      {...props}
      className={cx('logo', logoCss, props.className)}
      onClick={onClickAction}
    >
      <Logo.SimpleColor
        className={cx(
          'logo',
          css`
            padding: ${px2grid(16)} 0;
          `,
          props.className
        )}
      />
    </div>
  );
};

AmpliLogo.propTypes = {
  className: string,
};

export default AmpliLogo;
