import React from 'react';
import { BlogCard } from '../blog-card';
import Carousel from '../social-proof/carousel';
import { useBlogResponse } from '../../hooks';
import { cx } from '@ampli/ui';
import styles from './styles';

interface BlogPostInterface {
  title: string;
  subTitle: string;
  postLink: string;
  imageURL: string;
}
interface BlogSectionProps {
  pageType: 'home' | 'level' | 'course';
}
const BlogSection: React.FC<BlogSectionProps> = ({
  pageType,
}: BlogSectionProps) => {
  const posts = useBlogResponse(pageType);

  return (
    <>
      {posts.length > 0 && (
        <div className={cx('blog-section', styles.blogSection)}>
          <h2 className={cx('blog-section-title', styles.blogSectionTitle)}>
            Publicações relacionadas - Blog da Ampli
          </h2>
          <div
            className={cx(
              'blog-section-container',
              styles.blogSectionContainer
            )}
          >
            <div
              className={cx('blog-section-divider', styles.blogSectionDivider)}
            />
            <div
              className={cx(
                'blog-section-cards-content',
                styles.blogSectionCardsContent
              )}
            >
              <Carousel
                responsive={{ desktop: { items: 3 }, mobile: { items: 1 } }}
              >
                {posts.map((post: BlogPostInterface, index: number) => (
                  <BlogCard
                    title={post.title}
                    image={post.imageURL}
                    link={post.postLink}
                    subtitle={post.subTitle}
                    key={index}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogSection;
