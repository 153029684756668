import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _excluded = ["top", "left", "width", "height"];

var _templateObject, _templateObject2, _templateObject3;

import { css } from '@emotion/css';
import { px2rem, px2grid } from "../../utils";
export var main = function main() {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  backdrop-filter: blur(3px);\n  background-image: linear-gradient(\n    82.37deg,\n    rgba(59, 219, 184, 0.8) 0%,\n    rgba(1, 182, 208, 0.8) 100%\n  );\n  bottom: 0;\n  left: 0;\n  overflow: hidden;\n  padding-top: ", ";\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 1;\n"])), px2grid(64));
};
export var cloneWrap = function cloneWrap(_ref) {
  var _ref$top = _ref.top,
      top = _ref$top === void 0 ? 0 : _ref$top,
      _ref$left = _ref.left,
      left = _ref$left === void 0 ? 0 : _ref$left,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? 0 : _ref$width,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? 0 : _ref$height;
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: ", " !important;\n  left: ", " !important;\n  position: absolute !important;\n  top: ", " !important;\n  width: ", " !important;\n"])), px2rem(height), px2rem(left), px2rem(top), px2rem(width));
};
export var clone = function clone(_ref2) {
  var top = _ref2.top,
      left = _ref2.left,
      width = _ref2.width,
      height = _ref2.height,
      styles = _objectWithoutProperties(_ref2, _excluded);

  return css(styles);
};
export var closeButton = function closeButton() {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  padding: ", " !important;\n"])), px2grid(64), px2grid(16), px2grid(16));
};