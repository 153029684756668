import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect, useCallback } from 'react';
import { useFormik, omit } from '@ampli/utils';
import { useUpdateLead } from '@ampli/services';

var useUpdateLeadForm = function useUpdateLeadForm(_ref) {
  var _ref$settings = _ref.settings,
      settings = _ref$settings === void 0 ? {} : _ref$settings,
      onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      onCompleted = _ref.onCompleted,
      initialValues = _ref.initialValues;

  var _useUpdateLead = useUpdateLead({
    onError: onError,
    onCompleted: onCompleted
  }),
      _useUpdateLead2 = _slicedToArray(_useUpdateLead, 3),
      updateLead = _useUpdateLead2[0],
      _useUpdateLead2$ = _useUpdateLead2[1],
      data = _useUpdateLead2$.data,
      loading = _useUpdateLead2$.loading,
      error = _useUpdateLead2$.error,
      called = _useUpdateLead2$.called,
      validationSchema = _useUpdateLead2[2];

  var handleUpdateLead = function handleUpdateLead(values, captcha) {
    var lead = _objectSpread(_objectSpread({}, omit(initialValues, 'id')), {}, {
      name: initialValues.name,
      email: initialValues.email,
      phone: initialValues.phone,
      courseId: initialValues.courseId,
      postalCode: initialValues.postalCode,
      cityId: initialValues.cityId,
      stateId: initialValues.stateId
    });

    updateLead({
      variables: {
        id: values.id,
        lead: lead,
        captcha: captcha
      }
    });
    onSubmit && onSubmit(values);
  };

  var formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: function onSubmit(values) {
      var _window;

      var grecaptcha = (_window = window) === null || _window === void 0 ? void 0 : _window.grecaptcha;

      if (grecaptcha) {
        grecaptcha.ready(function () {
          grecaptcha.execute(settings.recaptchaSiteKey, {
            action: 'updateLead'
          }).then(function (token) {
            handleUpdateLead(values, {
              token: token,
              origin: 'web'
            });
          });
        });
      } else {
        throw new Error("Google reCaptcha doesn't exist.");
      }
    }
  });
  var onErrorCallback = useCallback(onError, []);
  useEffect(function () {
    if (called && !loading && error) {
      onErrorCallback(error);
    }
  }, [called, loading, error, onErrorCallback]);
  return {
    formik: formik,
    updateLead: updateLead,
    data: data,
    loading: loading
  };
};

export default useUpdateLeadForm;