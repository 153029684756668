import React from 'react';
import BaseHeader from './base-header';
import { useLocation } from 'react-router-dom';
import { func } from '@ampli/utils';
import { HEADER_LINKS } from '../../constants';

const Header = ({ pageTopScroller, contactScroller, ...props }) => {
  const { pathname } = useLocation();

  function resolveLocalLink(path) {
    return pathname === path ? { onClick: pageTopScroller } : { to: path };
  }

  const menuItems = [
    { label: 'início', ...resolveLocalLink('/') },
    { label: 'cursos', ...resolveLocalLink('/cursos') },
    { label: 'contato', onClick: contactScroller },
    {
      label: 'dúvidas frequentes',
      href: HEADER_LINKS['FAQ'],
      target: '_blank',
    },
    {
      label: 'blog',
      href: HEADER_LINKS['blog'],
      target: '_blank',
    },
  ];

  return <BaseHeader {...props} items={menuItems} />;
};
Header.propTypes = {
  pageTopScroller: func,
  contactScroller: func,
};

export default Header;
