import { useState, useEffect } from 'react';

function UseGetPageURL(url, type) {
  const [pageURL, setPageURL] = useState();

  useEffect(() => {
    const urlArray = url.split('/');
    if (type === 'home')
      setPageURL({
        level: '',
        course: '',
      });
    if (type === 'level')
      setPageURL({
        level: urlArray[3],
        course: '',
      });
    if (type === 'course')
      setPageURL({
        level: urlArray[3],
        course: urlArray[4],
      });
  }, [url, type]);

  return pageURL;
}

export default UseGetPageURL;
