import { useState, useEffect } from 'react';

function useFetch(url) {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch(console.error);
  }, [url]);

  return data;
}

export default useFetch;
