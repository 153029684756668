import React, { FunctionComponent } from 'react';

import {
  css,
  cx,
  TextButton,
  px2rem,
  fontSize,
  IconButton,
  Flex,
  FlexItem,
} from '@ampli/ui';
import { Menu as MenuIcon } from '@ampli/icon';

import AmpliLogo from '../header-logo';

import { HeaderProps } from './types';
import { OnClickFunction } from '../../../types';

interface MobileHeaderProps extends HeaderProps {
  onClickMenu: OnClickFunction;
}

const MobileHeader: FunctionComponent<MobileHeaderProps> = ({
  onClickMenu,
  onSubscribeClick,
  className,
}: MobileHeaderProps) => (
  <FlexItem flex={1}>
    <Flex direction="row" justifyContent="space-between" alignItems="center">
      <div className={className}>
        <IconButton
          className={cx(
            'icon-button-menu',
            fontSize('x-small'),
            css`
              letter-spacing: ${px2rem(5)};
            `
          )}
          icon={<MenuIcon />}
          iconColor="text-light"
          onClick={onClickMenu}
        >
          {'menu'}
        </IconButton>
        <AmpliLogo />

        <TextButton
          className={cx(
            'subscribe-button-menu',
            fontSize('x-small'),
            css`
              letter-spacing: ${px2rem(5)};
            `
          )}
          onClick={onSubscribeClick}
          underline
        >
          {'inscreva-se'}
        </TextButton>
      </div>
    </Flex>
  </FlexItem>
);

MobileHeader.displayName = 'MobileHeader';

export default MobileHeader;
