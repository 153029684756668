import React from 'react';
const SvgRunning = (props) => {
  return (
    <svg
      {...props}
      width="542"
      height="512"
      viewBox="0 0 542 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.32">
        <path
          d="M266.34 296.439C254.936 272.988 195.351 273.116 203.903 213.035C206.302 196.164 231.124 201.982 229.488 186.559C226.292 156.527 244.405 140.11 260.848 136.272C286.15 130.368 313.432 123.548 334.707 145.285C342.564 153.314 331.237 173.57 334.218 191.7C336.061 202.89 367.344 190.098 367.198 204.15C366.804 241.499 343.018 249.262 326.31 252.887C303.364 257.865 275.165 314.579 266.34 296.439Z"
          fill="url(#paint0_linear_13863_34917)"
        />
        <path
          d="M279.65 508.017C278.056 508.017 276.728 506.758 276.659 505.147C276.574 503.099 267.8 299.43 270.087 256.425C272.401 213.112 291.68 161.317 291.877 160.803C292.459 159.252 294.19 158.464 295.741 159.046C297.292 159.629 298.08 161.36 297.498 162.91C297.309 163.416 278.347 214.363 276.085 256.742C273.806 299.455 282.572 502.833 282.657 504.881C282.726 506.535 281.449 507.932 279.787 508.009C279.744 508.017 279.693 508.017 279.65 508.017Z"
          fill="#90DAE0"
        />
        <path
          d="M279.659 216.043C278.716 216.043 277.783 215.597 277.2 214.766L250.569 176.706C249.618 175.352 249.953 173.475 251.306 172.533C252.66 171.582 254.537 171.916 255.479 173.27L282.11 211.33C283.061 212.684 282.726 214.56 281.373 215.503C280.85 215.863 280.25 216.043 279.659 216.043Z"
          fill="#90DAE0"
        />
        <path
          d="M274.438 244.969C273.325 244.969 272.262 244.352 271.739 243.29C271.011 241.799 271.628 240.008 273.119 239.28L324.992 213.943C326.482 213.215 328.273 213.832 329.002 215.323C329.73 216.814 329.113 218.605 327.622 219.333L275.749 244.67C275.33 244.867 274.884 244.969 274.438 244.969Z"
          fill="#90DAE0"
        />
        <path
          d="M288.823 182.224C287.444 182.224 286.201 181.264 285.893 179.859C285.541 178.239 286.578 176.646 288.198 176.294L307.939 172.053C309.55 171.71 311.152 172.738 311.503 174.358C311.855 175.977 310.818 177.571 309.199 177.922L289.457 182.164C289.243 182.198 289.029 182.224 288.823 182.224Z"
          fill="#90DAE0"
        />
        <path
          d="M272.516 272.868C271.908 272.868 271.291 272.679 270.76 272.294L247.402 255.32C246.066 254.343 245.766 252.467 246.743 251.13C247.719 249.793 249.596 249.494 250.933 250.47L274.29 267.444C275.627 268.421 275.926 270.297 274.95 271.634C274.358 272.439 273.45 272.868 272.516 272.868Z"
          fill="#90DAE0"
        />
        <path
          d="M253.155 127.112C255.083 135.363 234.09 140.547 227.878 156.707C221.666 172.867 223.791 179.61 221.306 184.408C218.821 189.207 205.506 188.316 207.991 176.243C210.476 164.161 247.02 100.91 253.155 127.112Z"
          fill="url(#paint1_linear_13863_34917)"
        />
      </g>
      <path
        d="M165.899 158.332C165.899 158.332 161.94 172.221 157.373 176.548C152.806 180.875 137.452 189.024 132.456 196.187C127.461 203.35 89.5119 208.508 83.8996 182.623C81.0892 169.659 90.8742 158.092 108.105 156.772C128.592 155.204 137.597 152.857 143.758 146.345C156.062 133.346 174.732 144.211 165.899 158.332Z"
        fill="#3D2547"
      />
      <path
        d="M161.031 177.259C161.031 177.259 206.743 198.92 215.946 198.68C222.578 198.509 223.854 158.323 223.854 158.323L229.895 157.903C229.895 157.903 256.337 205.183 240.014 221.095C223.691 237.006 176.12 218.884 176.12 218.884L161.031 177.259Z"
        fill="#FD6467"
      />
      <path
        d="M229.894 157.903C229.894 157.903 231.042 152.317 227.743 149.721C224.436 147.124 223.382 148.187 223.142 149.721C222.902 151.254 221.959 150.286 221.728 152.068C221.488 153.85 223.861 158.323 223.861 158.323L229.894 157.903Z"
        fill="#FD6467"
      />
      <path
        d="M267.054 494.964C268.159 493.585 269.367 493.156 270.387 493.114C271.518 494.407 273.283 495.513 275.991 495.513C281.706 495.856 288.818 496.404 289.717 497.912C291.217 500.422 263.73 512.418 258.04 511.861C255.358 511.596 255.418 508.622 256.018 504.938C257.774 504.544 264.458 498.186 267.054 494.964Z"
        fill="#3D2547"
      />
      <path
        d="M268.435 489.112C268.435 489.112 229.929 346.055 206.503 280.439C199.263 260.166 182.075 243.681 178.099 232.105C170.028 208.654 188.647 201.045 177.628 188.766C162.984 172.444 150.895 173.481 134.992 182.76C123.99 189.178 147.296 215.637 144.168 231.128C142.695 238.403 135.977 244.049 129.071 252.446C118.223 265.273 112.971 281.142 112.268 297.824C111.686 310.865 112.791 346.715 99.9986 346.047C77.601 344.881 35.9589 370.869 23.0293 379.343C20.3731 381.083 17.3999 382.282 14.2553 382.711C12.5416 382.942 10.7594 382.942 9.02006 382.042C8.64305 381.845 8.28318 381.665 7.91474 381.485C7.77765 383.285 7.70054 385.487 7.8719 387.749C8.28318 393.138 11.899 391.527 14.221 394.955C15.215 396.42 15.2749 397.697 15.0093 398.682C17.2114 397.688 19.4134 397.988 19.4134 397.988C163.164 419.597 167.731 322.141 167.731 322.141C169.376 327.128 189.289 373.157 193.436 399.83C200.514 445.337 253.209 494.108 253.209 494.108C254.151 496.901 256.936 499.874 256.225 503.73C256.148 504.141 256.079 504.552 256.011 504.946C257.767 504.552 259.901 503.978 262.017 503.156C267.047 501.194 264.459 498.203 267.047 494.973C268.152 493.593 269.36 493.165 270.38 493.122C268.795 491.297 268.435 489.112 268.435 489.112Z"
        fill="#FD6467"
      />
      <path
        d="M176.121 218.884C176.121 218.884 177.372 219.364 179.539 220.092C179.437 215.534 179.265 205.269 179.591 202.005C179.916 198.809 175.152 204.721 173.07 210.461L176.121 218.884Z"
        fill="#3D2547"
      />
      <path
        d="M184.26 243.278C181.484 239.165 179.248 235.43 178.1 232.105C170.028 208.654 188.647 201.045 177.628 188.766C162.985 172.444 150.895 173.481 134.992 182.76C123.991 189.178 147.297 215.637 144.169 231.128C143.261 235.618 140.348 239.491 136.638 243.767C144.229 244.949 166.241 247.682 184.26 243.278Z"
        fill="#90DAE0"
      />
      <path
        d="M152.612 196.264L136.512 203.29C137.454 205.458 138.448 207.694 139.408 209.939C140.616 209.348 142.321 208.585 144.737 207.608C151.918 204.721 152.612 196.264 152.612 196.264Z"
        fill="#3D2547"
      />
      <path
        d="M145.053 177.919C122.492 183.454 68.743 192.125 58.8294 211.404C45.9684 236.424 76.1032 239.474 100.909 239.208C122.895 238.977 133.888 235.798 133.888 235.798L135.43 230.074C135.43 230.074 98.4751 220.135 98.3123 217.145C98.2095 215.123 125.637 209.725 143.065 205.038C160.604 200.325 151.479 176.334 145.053 177.919Z"
        fill="#FD6467"
      />
      <path
        d="M135.429 230.074C135.429 230.074 142.232 229.672 142.789 231.711C143.346 233.75 140.39 238.017 133.887 235.807L135.429 230.074Z"
        fill="#FD6467"
      />
      <path
        d="M154.355 164.561L155.127 177.465C155.264 179.59 160.885 184.662 164.038 181.74C164.963 180.884 163.464 178.056 162.384 175.923L159.059 165.032L154.355 164.561Z"
        fill="#FD6467"
      />
      <path
        d="M161.032 171.493C158.205 170.842 155.129 166.891 155.129 166.891L159.533 166.574L161.032 171.493Z"
        fill="#3D2547"
      />
      <path
        d="M163.311 154.373C162.805 155.127 162.274 155.847 161.829 156.31C160.492 157.698 154.306 161.382 153.894 160.345C153.809 160.123 153.671 159.78 153.526 159.369L153.363 161.314C153.029 165.281 155.822 168.991 159.781 169.402C163.439 169.788 166.772 167.457 166.978 162.856C167.124 159.223 164.682 156.224 163.311 154.373Z"
        fill="#FD6467"
      />
      <path
        d="M166.892 161.639C166.892 161.639 166.995 162.385 166.318 162.676C165.906 162.847 164.398 163.053 163.602 162.35C163.602 162.35 165.358 162.453 166.892 161.639Z"
        fill="white"
      />
      <path
        d="M160.087 167.757C161.577 167.697 164.165 166.917 165.185 165.94C166.084 165.066 166.804 163.079 166.958 162.633C166.958 162.71 166.967 162.779 166.958 162.856C166.753 167.457 163.42 169.788 159.761 169.402C157.319 169.145 155.331 167.637 154.234 165.589C156.394 167.337 158.75 167.808 160.087 167.757Z"
        fill="url(#paint2_linear_13863_34917)"
      />
      <path
        d="M154.792 159.831C154.501 159.506 154.261 159.129 154.038 158.752C153.627 158.023 152.839 157.038 152.059 158.178C150.928 159.848 153.344 163.61 155.315 162.882C156.78 162.342 155.529 160.662 154.792 159.831Z"
        fill="#FD6467"
      />
      <path
        d="M206.504 280.439C201.345 265.993 191.141 253.475 184.26 243.278C166.241 247.682 144.229 244.94 136.629 243.767C134.324 246.423 131.711 249.233 129.072 252.446C118.224 265.273 112.972 281.142 112.269 297.824C112.029 303.239 112.072 312.579 111.301 321.61C116.562 325.414 135.721 337.813 163.996 339.466C167.398 329.381 167.74 322.141 167.74 322.141C168.314 323.863 171.073 330.512 174.604 339.561C188.724 338.995 204.678 335.611 221.986 327.462C216.503 309.863 211.199 293.591 206.504 280.439Z"
        fill="#AFE8E5"
      />
      <path
        d="M133.887 326.494C134.598 324.352 133.176 320.282 130.605 315.783C128.035 311.285 128.677 312.784 127.855 306.572C127.032 300.36 127.606 296.505 122.894 291.424C119.561 287.825 118.584 276.42 118.301 270.054C114.625 278.751 112.672 288.151 112.269 297.824C112.029 303.239 112.072 312.579 111.301 321.61C113.94 323.521 120.092 327.591 129.131 331.386C131.325 329.553 133.544 327.522 133.887 326.494Z"
        fill="#D7F2F0"
      />
      <path
        d="M154.357 262.231C151.556 264.373 147.306 271.014 148.377 279.368C149.448 287.722 158.873 297.361 151.77 301.646C144.667 305.93 146.877 310.857 147.734 320.282C148.377 327.394 145.275 333.64 143.707 336.287C149.782 337.855 156.568 339.029 163.988 339.466C167.39 329.381 167.733 322.141 167.733 322.141C167.733 322.141 172.162 299.289 170.235 279.368C168.307 259.447 157.168 260.089 154.357 262.231Z"
        fill="#D7F2F0"
      />
      <path
        d="M206.865 317.925C207.936 310.428 204.723 302.074 200.01 298.433C195.297 294.791 192.084 306.572 192.084 306.572C192.084 306.572 193.584 316.854 189.942 323.281C187.655 327.316 189.085 333.631 190.465 337.77C197.011 336.562 203.866 334.711 210.978 332.08C209.067 327.83 206.239 322.27 206.865 317.925Z"
        fill="#D7F2F0"
      />
      <path
        d="M191.014 287.508C192.299 280.225 196.798 277.654 192.942 271.442C189.086 265.23 188.658 273.156 184.262 280.653C179.867 288.151 184.262 294.363 184.262 294.363C184.365 298.218 189.729 294.791 191.014 287.508Z"
        fill="#D7F2F0"
      />
      <path
        d="M154.312 211.627C154.312 211.627 155.264 224.316 155.144 226.887L172.469 225.85C173.454 225.79 174.2 224.925 174.097 223.939C173.805 221.086 173.437 215.542 174.44 212.372C174.671 211.652 174.131 210.916 173.377 210.941L154.312 211.627Z"
        fill="#E6F5F4"
      />
      <path
        d="M178.101 232.105C170.03 208.654 188.649 201.045 177.63 188.766C172.3 182.829 167.305 179.196 162.344 177.413C165.291 182.263 171.023 192.237 171.735 197.532C172.694 204.712 165.523 215.74 168.95 226.878C170.749 232.722 173.08 239.654 174.888 244.932C178.067 244.555 181.228 244.015 184.27 243.278C181.485 239.165 179.241 235.43 178.101 232.105Z"
        fill="url(#paint3_linear_13863_34917)"
      />
      <path
        d="M14.2221 394.946C15.216 396.411 15.276 397.688 15.0104 398.673C13.4424 399.376 11.8743 400.738 11.0775 403.326C9.08107 408.69 6.4763 415.33 4.7712 415.75C1.93508 416.453 -1.50082 386.652 0.701243 381.374C1.73801 378.881 4.56556 379.815 7.91578 381.468C7.77012 383.276 11.9001 391.527 14.2221 394.946Z"
        fill="#3D2547"
      />
      <path
        d="M443.492 126.386C443.492 126.386 446.894 125.315 449.01 126.548C449.918 127.08 451.307 130.216 451.307 130.216L451.812 133.112C451.812 133.112 451.709 136.411 450.929 137.113C449.019 138.853 444.915 132.435 444.915 132.435L443.492 126.386Z"
        fill="#3D2547"
      />
      <path
        d="M446.111 239.976C470.625 248.519 504.795 233.37 534.031 196.5C534.031 196.5 535.701 196.8 539.926 194.795C544.15 192.79 541.691 184.95 534.63 189.646C529.961 192.747 474.549 197.006 459.4 206.208C454.268 209.327 452.049 159.811 433.91 154.944C405.951 147.438 432.582 235.255 446.111 239.976Z"
        fill="#FD6467"
      />
      <path
        d="M386.511 276.092L378.483 351.407C378.483 351.407 360.207 369.349 357.114 379.34C350.267 401.498 343.678 471.989 339.934 495.355C339.591 497.489 337.449 502.073 337.449 502.073L347.843 504.138L357.208 496.126L445.89 322.918L457.243 255.793L386.511 276.092Z"
        fill="#FD6467"
      />
      <path
        d="M386.511 276.092L378.483 351.407C378.483 351.407 360.207 369.349 357.114 379.34C350.267 401.498 343.678 471.989 339.934 495.355C339.591 497.489 337.449 502.073 337.449 502.073L347.843 504.138L357.208 496.126L445.89 322.918L457.243 255.793L386.511 276.092Z"
        fill="url(#paint4_linear_13863_34917)"
      />
      <path
        d="M382.568 313.09L381.043 327.416C389.928 327.699 407.828 327.476 416.524 321.804C425.615 315.874 397.743 312.807 382.568 313.09Z"
        fill="#3D2547"
      />
      <path
        d="M443.611 327.082L445.89 322.918L457.243 255.793L386.511 276.092L375.801 319.61C389.15 322.592 416.123 327.63 443.611 327.082Z"
        fill="#90DAE0"
      />
      <path
        d="M452.23 241.561C457.38 242.504 462.864 242.529 468.57 241.63C470.087 230.757 467.602 217.047 467.602 217.047L452.23 241.561Z"
        fill="#3D2547"
      />
      <path
        d="M353.045 495.338C354.75 494.259 356.207 494.233 357.329 494.567C358.083 496.383 359.574 498.217 362.505 499.211C368.554 501.67 376.043 504.875 376.462 506.828C377.165 510.093 343.055 512.989 337.108 510.298C334.306 509.03 335.463 505.834 337.459 502.073C339.507 502.296 349.061 497.874 353.045 495.338Z"
        fill="#3D2547"
      />
      <path
        d="M381.773 241.373C407.504 205.437 385.946 166.485 399.587 158.894C422.396 146.196 462.136 149.34 467.911 171.404C469.882 178.935 470.413 196.783 469.299 206.183C466.643 228.529 457.235 255.793 457.235 255.793C443.911 255.913 412.268 258.655 381.773 241.373Z"
        fill="#E5B5C4"
      />
      <path
        d="M420.649 211.709C420.649 211.709 451.349 216.884 464.767 214.597C464.767 214.597 464.03 231.613 458.598 240.113C458.598 240.113 433.296 240.293 419.672 234.312L420.649 211.709Z"
        fill="#F4F4F4"
      />
      <path
        d="M347.592 435.086C346.804 441.246 346.033 447.441 345.305 453.456L375.799 425.917L347.592 435.086Z"
        fill="#3D2547"
      />
      <path
        d="M375.801 319.619C389.15 322.592 416.123 327.63 443.611 327.09L445.358 323.903C412.722 323.406 383.547 316.011 377.12 314.272L375.801 319.619Z"
        fill="#F4F4F4"
      />
      <path
        d="M439.256 139.881L435.906 154.533C436.172 156.589 448.005 160.77 450.892 157.72C451.741 156.829 451.201 154.901 450.018 152.888L443.104 141.997L439.256 139.881Z"
        fill="#FD6467"
      />
      <path
        d="M444.492 144.191C442.17 144.105 439 143.934 439 143.934C439 143.934 439.694 147.515 443.224 149.794C445.092 151.003 447.14 151.422 449.008 151.294L444.492 144.191Z"
        fill="#3D2547"
      />
      <path
        d="M441.116 134.046L439.034 142.426C438.563 145.236 440.414 147.918 443.216 148.466C445.452 148.903 446.874 148.8 449.659 148.261C451.981 147.807 453.018 145.476 452.795 142.408C452.504 138.433 451.107 134.851 450.259 132.975C448.305 128.699 442.881 128.691 441.116 134.046Z"
        fill="#FD6467"
      />
      <path
        d="M452.366 140.763C452.366 140.763 452.512 141.535 451.878 141.929C451.244 142.323 449.976 142.091 449.453 141.235C449.453 141.235 450.687 141.226 452.366 140.763Z"
        fill="white"
      />
      <path
        d="M440.881 145.519C439.964 144.885 439.382 143.231 439.073 142.246L439.03 142.426C438.559 145.236 440.41 147.918 443.212 148.466C445.448 148.903 446.87 148.8 449.655 148.261C451.069 147.986 452.003 147.01 452.474 145.596C452.011 145.699 445.808 148.938 440.881 145.519Z"
        fill="url(#paint5_linear_13863_34917)"
      />
      <path
        d="M381.771 241.373C381.771 241.373 355.552 281.301 406.876 325.522L423.525 342.53C423.525 342.53 387.632 334.613 360.367 370.626C333.103 406.639 313.824 443.945 313.824 443.945L318.511 453.396L328.553 455.778C328.553 455.778 426.746 394 456.607 373.822C491.094 350.516 457.25 255.785 457.25 255.785C457.25 255.785 396.766 250.926 381.771 241.373Z"
        fill="#FD6467"
      />
      <path
        d="M416.522 191.865L395.264 191.402C395.384 197.023 395.247 203.081 394.355 209.379C402.195 205.163 416.522 191.865 416.522 191.865Z"
        fill="#3D2547"
      />
      <path
        d="M472.094 332.531C472.103 329.892 472.026 327.21 471.871 324.511C469.19 322.403 466.773 320.698 466.773 320.698L447.777 333.611C447.769 333.611 462.969 332.951 472.094 332.531Z"
        fill="#3D2547"
      />
      <path
        d="M457.243 255.793C457.243 255.793 396.768 250.926 381.773 241.373C381.773 241.373 353.489 281.378 402.645 325.068C403.691 325.994 404.702 326.962 405.661 327.973L409.312 332.523C411.248 334.939 414.204 336.301 417.306 336.216C420.999 336.104 425.274 335.881 429.901 335.53C431.769 335.384 426.388 314.289 428.359 314.101C431.067 313.844 441.16 334.468 443.988 334.116C454.424 332.814 465.348 330.86 474.645 328.11C475.793 295.645 457.243 255.793 457.243 255.793Z"
        fill="#90DAE0"
      />
      <path
        d="M448.958 129.77C449.181 130.721 448.864 131.724 448.152 132.401C447.098 133.403 445.342 135.22 444.562 136.788C443.817 138.287 442.617 138.861 441.589 139.315C440.561 139.769 438.23 144.362 437.922 145.733C437.922 145.733 431.393 146.35 427.871 138.818L437.039 134.474V133.043C437.039 133.043 436.705 131.038 437.313 129.882C438.959 126.763 443.705 125.34 446.113 126.3C447.715 126.951 448.641 128.408 448.958 129.77Z"
        fill="#3D2547"
      />
      <path
        d="M442.802 139.435C442.494 138.998 442.254 138.518 442.048 138.021C441.654 137.088 440.849 135.785 439.743 137.053C438.141 138.904 440.523 143.779 442.999 143.18C444.842 142.734 443.573 140.541 442.802 139.435Z"
        fill="#FD6467"
      />
      <path
        d="M437.036 134.466C437.036 134.466 441.449 136.762 451.799 133.112C451.799 133.112 452.193 131.33 451.294 130.216C451.294 130.216 445.878 132.692 437.027 133.035V134.466H437.036Z"
        fill="#90DAE0"
      />
      <path
        d="M444.568 331.083C441.671 330.698 438.972 323.552 436.076 318.342C433.18 313.133 429.316 309.851 425.614 311.393C421.913 312.935 427.002 333.011 427.002 333.011C422.744 334.433 414.441 333.439 409.395 332.617C411.331 334.973 414.244 336.301 417.303 336.216C420.996 336.104 425.272 335.881 429.899 335.53C431.766 335.384 426.386 314.289 428.356 314.101C431.064 313.844 441.157 334.468 443.985 334.116C454.421 332.814 465.346 330.86 474.642 328.11C474.677 327.185 474.702 326.251 474.711 325.317C465.903 327.253 446.787 331.375 444.568 331.083Z"
        fill="#F4F4F4"
      />
      <path
        d="M425.615 312.353C425.615 312.353 407.973 276.092 409.558 249.65L414.15 250.464C414.15 250.464 411.751 277.223 429.899 312.353H425.615Z"
        fill="#F4F4F4"
      />
      <path
        d="M386.648 161.739C361.089 173.083 351.972 179.912 347.893 193.647C340.508 218.538 357.241 251.955 395.439 279.442C395.439 279.442 392.14 280.539 394.342 284.66C396.544 288.781 403.408 285.208 402.311 279.716C401.214 274.224 392.14 220.046 382.252 205.343C378.902 200.365 428.256 195.832 432.266 177.479C438.452 149.203 412.199 150.403 386.648 161.739Z"
        fill="#FD6467"
      />
      <path
        d="M328.108 453.122C329.959 453.927 330.79 455.135 331.142 456.241C330.053 457.877 329.368 460.148 330.173 463.13C331.502 469.522 333.018 477.525 331.63 478.964C329.317 481.363 307.879 454.673 306.799 448.238C306.285 445.196 309.584 444.382 313.825 443.945C314.785 445.77 323.781 451.228 328.108 453.122Z"
        fill="#3D2547"
      />
      <path
        d="M106.415 62.1924C138.975 68.5929 143.259 61.6355 180.96 68.5929C218.661 75.5504 160.396 76.0388 198.097 84.4444C235.797 92.8499 405.125 96.6799 389.17 77.444C378.254 64.2831 395.168 62.7322 408.878 62.1838C422.587 61.6355 439.724 30.481 424.301 21.5871C408.878 12.6931 388.314 28.8187 350.613 19.3593C312.912 9.89986 321.481 -5.67737 279.496 2.11125C237.511 9.89986 249.507 28.8102 208.379 27.1479C167.251 25.4857 32.942 47.7548 106.415 62.1924Z"
        fill="#D7F2F0"
        fillOpacity="0.47"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13863_34917"
          x1="285.129"
          y1="289.24"
          x2="285.129"
          y2="166.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0243" stopColor="#AFE8E5" />
          <stop offset="1" stopColor="#90DAE0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13863_34917"
          x1="230.478"
          y1="289.241"
          x2="230.478"
          y2="166.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0243" stopColor="#AFE8E5" />
          <stop offset="1" stopColor="#90DAE0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13863_34917"
          x1="160.403"
          y1="163.096"
          x2="162.575"
          y2="174.356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.0933" stopColor="#F2EEF6" />
          <stop offset="0.3877" stopColor="#CBBFDB" />
          <stop offset="0.6475" stopColor="#AF9CC8" />
          <stop offset="0.8605" stopColor="#9E87BC" />
          <stop offset="1" stopColor="#987FB8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_13863_34917"
          x1="164.02"
          y1="211.178"
          x2="208.524"
          y2="211.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.2209" stopColor="#CFD9F9" />
          <stop offset="0.4737" stopColor="#A0B3F3" />
          <stop offset="0.6971" stopColor="#7D97EF" />
          <stop offset="0.8802" stopColor="#6886EC" />
          <stop offset="1" stopColor="#6080EB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_13863_34917"
          x1="415.699"
          y1="295.261"
          x2="395.237"
          y2="375.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.241" stopColor="#E2C9D5" />
          <stop offset="0.4872" stopColor="#C89AAF" />
          <stop offset="0.7049" stopColor="#B67794" />
          <stop offset="0.8832" stopColor="#AA6283" />
          <stop offset="1" stopColor="#A65A7D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_13863_34917"
          x1="438.895"
          y1="142.597"
          x2="450.949"
          y2="148.718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.0933" stopColor="#F2EEF6" />
          <stop offset="0.3877" stopColor="#CBBFDB" />
          <stop offset="0.6475" stopColor="#AF9CC8" />
          <stop offset="0.8605" stopColor="#9E87BC" />
          <stop offset="1" stopColor="#987FB8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgRunning;
