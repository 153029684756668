import React, { FunctionComponent } from 'react';
import { cx, Flex } from '@ampli/ui';
import Header from './header';
import Statement from './statement';
import styles from './styles';
import { Testimonial } from '../types';

interface MediaSocialProofProps {
  className?: string;
  items: Testimonial[];
}

const MediaSocialProof: FunctionComponent<MediaSocialProofProps> = ({
  className,
  items,
  ...props
}: MediaSocialProofProps) => {
  const [selectedElement, setSelectedElement] = React.useState(0);
  const [selectedTestimonial, setSelectedTestimonial] = React.useState(
    items[0].testimonial
  );

  React.useEffect(() => {
    setSelectedTestimonial(items[selectedElement].testimonial);
  }, [items, selectedElement]);

  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <div
        className={cx('media-social-proof', styles.wrapper, className)}
        {...props}
      >
        <Header
          items={items}
          selectedElement={selectedElement}
          onClickElement={setSelectedElement}
        />
        <Statement>{selectedTestimonial}</Statement>
      </div>
    </Flex>
  );
};

export default MediaSocialProof;
