import React, { FunctionComponent } from 'react';
import { cx, css, px2grid, px2rem, mediaQuery } from '@ampli/ui';
import { DefaultComponentWithClassNameProps } from '../../types';
import { Category } from './types';
import FooterSEOItem from './footer-seo-item';

interface FooterSEOProps extends DefaultComponentWithClassNameProps {
  categories: Category[];
}

const FooterSEO: FunctionComponent<FooterSEOProps> = ({
  categories,
  className,
}: FooterSEOProps) => {
  return (
    <div
      className={cx(
        'footer-seo-wrap',
        css`
          display: flex;
          flex-direction: column;
          margin-bottom: ${px2rem(48)};

          ${mediaQuery('desktop')} {
            flex-direction: row;
            justify-content: space-between;

            max-width: ${px2grid(680)};
            margin-bottom: ${px2rem(56)};
            margin-top: ${px2rem(16)};
          }

          width: 100%;
        `,
        className
      )}
    >
      {categories.map((category, index) => (
        <FooterSEOItem category={category} key={index} />
      ))}
    </div>
  );
};

export default FooterSEO;
