import React from 'react';

import {
  cx,
  css,
  px2grid,
  marginSize,
  mediaQuery,
  Text,
  PrimaryButton,
  GradientLinearWarning,
} from '@ampli/ui';
import { func, string } from '@ampli/utils';

const BannerMobileApp = ({ onClick, className, ...props }) => (
  <div
    className={cx(
      'banner-mobile-app-wrap',
      css`
        display: flex;
        width: 100%;
        padding-left: ${px2grid(20)};
        margin-bottom: ${px2grid(40)};
        ${mediaQuery('desktop')} {
          justify-content: center;
        }
      `,
      className
    )}
    {...props}
  >
    <div
      className={cx(
        'banner-mobile-description',
        css`
          display: flex;
          flex-direction: column;
          flex: 0.8;

          ${mediaQuery('tablet-min')} {
            flex: 1;
            max-width: ${px2grid(630)};
          }
        `
      )}
    >
      <Text
        className={marginSize('large', 'bottom')}
        color="brand-base"
        size="larger"
        as="h2"
      >
        <strong>{'Estude pelo celular'}</strong>
      </Text>
      <Text
        className={marginSize('small', 'bottom')}
        color="neutral-title"
        size="x-large"
        as="h3"
      >
        <strong>{'Aluno Ampli tem APP exclusivo para estudos'}</strong>
      </Text>
      <Text
        as="p"
        size="x-large"
        className={css`
          line-height: 150%;
          ${marginSize('small', 'bottom')}
        `}
      >
        Sabemos que nem todos têm fácil acesso a um computador. Pensando nisso,
        além de uma plataforma para computadores, desenvolvemos também um
        aplicativo para celulares Android e iPhone, totalmente focado para que
        você possa ter a melhor experiência de aprendizado possível!
      </Text>
      <PrimaryButton
        onClick={onClick}
        className={cx(
          'button-mobile-app',
          css`
            width: fit-content;
            background: ${GradientLinearWarning};
            align-self: center;
            margin-top: ${px2grid(32)};

            ${mediaQuery('desktop')} {
              align-self: flex-start;
            }
          `
        )}
        fluid={false}
      >
        {`Aproveite por um período gratuito`}
      </PrimaryButton>
    </div>
    <div
      className={css`
        flex: 0.2;
        background-image: url(${require('../../assets/smartphones.webp')});
        background-size: cover;
        background-position: 0 0;
        background-repeat: no-repeat;

        ${mediaQuery('tablet-min')} {
          display: none;
        }
      `}
    />
    <img
      src={require('../../assets/smartphones.webp')}
      className={cx(
        'image-banner-mobile',
        css`
          height: ${px2grid(550)};
          width: ${px2grid(500)};

          ${mediaQuery('mobile')} {
            display: none;
          }
        `
      )}
    />
  </div>
);

BannerMobileApp.propTypes = {
  className: string,
  onClick: func,
};

export default BannerMobileApp;
