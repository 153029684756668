import Parachute from './parachute';
import RunningLady from './running-lady';
import RunningMan from './running-man';
import RunningGirl from './running-girl';
import PlayingTennis from './playing-tennis';

export { default as Parachute } from './parachute';
export { default as RunningLady } from './running-lady';
export { default as RunningMan } from './running-man';
export { default as PlayingTennis } from './playing-tennis';
export { default as RunningGirl } from './running-girl';

export default {
  Parachute,
  RunningLady,
  RunningMan,
  PlayingTennis,
  RunningGirl,
};
