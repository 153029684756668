import React, { ReactNode, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  cx,
  css,
  px2grid,
  px2rem,
  Text,
  Flex,
  GradientButton,
  BrandedCard,
} from '@ampli/ui';
import { string, node, bool } from '@ampli/utils';
import styles from './styles';

import Illustrations from './illustrations';
import { Strip } from '../strip-text';
import { BF_DISCOUNT_PERCENTAGE_TEXT } from '../../constants';

interface DegreeCardProps {
  illustration: keyof typeof Illustrations;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  link: string;
  openLinkInNewTab?: boolean;
  className?: string;
  externalLink?: boolean;
  isBlackFriday?: boolean;
}

const DegreeCard: FunctionComponent<DegreeCardProps> = ({
  illustration,
  title,
  subtitle,
  children,
  link,
  openLinkInNewTab = false,
  externalLink = false,
  className,
  isBlackFriday = false,
  ...props
}: DegreeCardProps) => {
  const Illustration = Illustrations[illustration];

  return (
    <BrandedCard
      maxWidth={340}
      height={325}
      className={cx('degree-card', styles.degreeCard, className)}
      {...props}
    >
      <Flex
        direction="column"
        alignItems="flex-end"
        // TODO: rollback to space-between after BF
        justifyContent="space-around"
      >
        <div className="card-content">
          {illustration && <Illustration className="illustration" />}
          <Text
            as="h2"
            className="title-wrapper"
            aria-labelledby="title subtitle"
          >
            <Text
              id="title"
              color="semantic-alert"
              size="huge"
              className="title"
              block
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                id="subtitle"
                color="semantic-alert"
                size="x-large"
                className="subtitle"
              >
                {subtitle}
              </Text>
            )}
          </Text>

          <Text color="neutral-dark" className="description">
            {children}
          </Text>
          <GradientButton
            as={externalLink ? 'a' : Link}
            to={link}
            href={link}
            size="micro"
            target={openLinkInNewTab && '_'}
          >
            Conheça
          </GradientButton>
          {/* TODO: Remove after BF */}
          {title === 'Graduação' && isBlackFriday && (
            <Strip
              text={BF_DISCOUNT_PERCENTAGE_TEXT}
              isVertical={false}
              textColor="text-white"
              repeatTextNTimes={4}
              className={css`
                width: 100%;
                min-height: ${px2rem(16)};

                font-size: ${px2rem(14)};
                font-weight: 500;

                position: absolute;
                bottom: ${px2grid(14)};
                right: 0;

                display: flex;
                align-items: center;
                justify-content: space-around;

                &.text-strip-wrap {
                  .strip-text {
                    margin: 0;
                  }
                }
              `}
            />
          )}
        </div>
      </Flex>
    </BrandedCard>
  );
};

DegreeCard.propTypes = {
  illustration: string,
  title: string,
  subtitle: string,
  children: node,
  link: string,
  openLinkInNewTab: bool,
  className: string,
  externalLink: bool,
  isBlackFriday: bool,
};

export default DegreeCard;
