/* eslint-disable sonarjs/no-duplicate-string */
import { css, px2grid, getColor, mediaQuery } from '@ampli/ui';

export default {
  poloButtonCourse: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${getColor('white')};
    width: ${px2grid(300)};
    border: none;
    box-shadow: ${px2grid(0)} ${px2grid(8)} ${px2grid(32)} rgba(0, 0, 0, 0.14);
    border-radius: ${px2grid(8)};
    margin-bottom: ${px2grid(24)};
    padding: ${px2grid(30)};
    ${mediaQuery('mobile')} {
      width: ${px2grid(300)};
    }
  `,
  poloButtonText: css`
    font-size: ${px2grid(28)};
    line-height: 100%;
    color: ${getColor('neutral-dark')};
    margin-bottom: ${px2grid(0)};
    margin-right: auto;
  `,
  poloButtonArrow: css`
    path {
      stroke: ${getColor('neutral-dark')} !important;
      margin-left: auto;
    }
  `,
  poloRootBreadcrumbs: css`
    position: absolute;
    top: ${px2grid(120)};
    left: ${px2grid(100)};
    li {
      filter: none !important;
    }
    ${mediaQuery('mobile')} {
      top: ${px2grid(100)};
      left: ${px2grid(10)};
    }
  `,
  poloContent: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: ${px2grid(80)} ${px2grid(10)};
    ${mediaQuery('mobile')} {
      flex-direction: column-reverse;
      padding: ${px2grid(0)};
    }
  `,
  poloIllustrations: css`
    width: 50%;
    ${mediaQuery('mobile')} {
      width: 70%;
      height: 70%;
    }
  `,
  poloTitle: css`
    font-weight: 100;
    font-size: ${px2grid(96)};
    line-height: 100%;
    color: ${getColor('neutral-dark')};
    margin-bottom: ${px2grid(50)};
    transform: matrix(1, 0.01, -0.01, 1, 0, 0);
    ${mediaQuery('mobile')} {
      font-size: ${px2grid(50)};
      margin-top: ${px2grid(70)};
      margin-bottom: ${px2grid(25)};
    }
  `,
  poloButtonContent: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-inline: ${px2grid(50)};
    width: 40%;
    ${mediaQuery('mobile')} {
      width: auto;
      padding: ${px2grid(0)};
    }
  `,
};
