export var SizeGridBase = '4px';
export var SizeFontXSmall = '12px';
export var SizeFontSmall = '14px';
export var SizeFontBase = '16px';
export var SizeFontLarge = '18px';
export var SizeFontXLarge = '20px';
export var SizeFontXXLessLarge = '22px';
export var SizeFontXXLarge = '24px';
export var SizeFontXXXLarge = '28px';
export var SizeFontLessLarger = '30px';
export var SizeFontLarger = '32px';
export var SizeFontHuge = '40px';
export var SizeMaxWidthScreenMiniMobile = '319px';
export var SizeMinWidthScreenMobile = '320px';
export var SizeMaxWidthScreenMobile = '500px';
export var SizeMinWidthScreenTablet = '501px';
export var SizeMaxWidthScreenTablet = '768px';
export var SizeMinWidthScreenDesktop = '769px';
export var SizeMaxWidthScreenDesktop = '1919px';
export var SizeMinWidthScreenSuper = '1920px';
export var ColorNeutralTitle = '#444444';
export var ColorNeutralDarker = '#212121';
export var ColorNeutralDark = '#8e9294';
export var ColorNeutralDarkTransparent = 'rgba(150, 146, 144, 0.6)';
export var ColorNeutralLight = '#c0bebc';
export var ColorNeutralLighter = '#d6e1eb';
export var ColorNeutralSurface500 = '#b1c2d5';
export var ColorNeutralBackground = '#f5f7fb';
export var ColorNeutral900 = '#212529';
export var ColorNeutral700 = '#495057';
export var ColorNeutralLightBackground = '#ebf0f5';
export var ColorNeutralSurface = '#DBE3EC';
export var ColorTitleDark = ColorNeutralTitle;
export var ColorTextDark = ColorNeutralDarker;
export var ColorTextLight = ColorNeutralDark;
export var ColorTextPlaceholder = '#b3b0af';
export var ColorTextWhite = '#fff';
export var ColorTextWarn = '#DFAC48';
export var ColorLine = ColorNeutralLighter;
export var ColorBackground = ColorNeutralBackground;
export var ColorBorder = '#ecebed';
export var ColorBrandBaseLight = '#09bbcd';
export var ColorBrandBase = '#01b6d0';
export var ColorBrandLight = '#4dcddf';
export var ColorBrandLighter = '#ccf0f6';
export var ColorBrandDarker = '#01a4bb';
export var ColorBrandDark = '#009A98';
export var ColorAccentBase = '#ffb800';
export var ColorAccentLight = '#ffce4d';
export var ColorAccentLighter = '#ffedbf';
export var ColorSemanticSuccess = '#47d474';
export var ColorSemanticSuccessLight = '#91e5ac';
export var ColorSemanticSuccessLighter = '#d1f4dc';
export var ColorSemanticSuccessDark = '#3ba86b';
export var ColorSemanticError = '#ec1c40';
export var ColorSemanticErrorLight = '#f68ea0';
export var ColorSemanticErrorLighter = '#fac6cf';
export var ColorSemanticAlert = '#ff7a00';
export var ColorSemanticAlertLight = '#ffaf66';
export var ColorSemanticAlertLighter = '#ffebd9';
export var ColorSemanticApproved = '#0B53DE';
export var ColorFeedbackSuccess = '#008566';
export var ColorPrimary500 = '#004ECC';
export var ColorWhatsapp = '#2BB886';
export var GradientLinearBrand = 'linear-gradient(57.25deg, #3bdbb8 0%, #00b7d1 100%)';
export var GradientLinearBrandLight = 'linear-gradient(180deg, #ccf0f6 0%, #ffffff 100%)';
export var GradientLinearBrandAlt = 'linear-gradient(82.37deg, #3BDBB8 0%, #01B6D0 100%)';
export var GradientLinearInactive = 'linear-gradient(85.83deg, #c3e3dc 0%, #b7dce1 100%);';
export var GradientLinearAlert = 'linear-gradient(42.23deg, #f4c342 0%, #ff6b00 110%)';
export var GradientLinearBrandAlpha = 'linear-gradient(82.37deg, rgba(59, 219, 184, 0.3) 0%, #00b7d1 100%)';
export var GradientLinearWarning = ' linear-gradient(77.07deg, #F4C342 -0.33%, #FF7A00 110.61%);';
export var GradientLinearComingSoon = 'linear-gradient(82.37deg, #007787 0%, #006c91 100%);';
export var GradientLinearTimBanner = 'linear-gradient(77.20deg, #154277 40%, #317AD0 60%);';
export var ColorSodexoBase = '#2A2D83';
export var ColorSodexoAccentBase = '#fe2d45';
export var ColorSodexoDark = '#858994';
export var ColorSodexoBackground = 'rgba(230, 231, 232, 0.4)';
export var ColorSodexoDivisorDashedColor = ColorSodexoAccentBase;
export var ColorBackgroundTranslucent = 'rgba(0, 0, 0, 0.13)';
export var ColorKrotonNeutralDarker = '#4D4D4D';
export var ColorBrDistribuidoraBase = '#088C50';
export var ColorBrDistribuidoraAccentBase = '#FFCB31';
export var ColorUndergraduateBrandBase = '#3bdbb8';
export var FreeTrialBackgroundColor = '#ffe6c9';
export var FreeTrialBorderColor = '#ffd399';
export var FreeTrialButtonColor = '#004ecc';