import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useCountEnrolledCandidates } from '@ampli/services';
import { Modal, useSubscriptionModal } from '@ampli/registration';
import { useScroller, useCheckDateIsInPeriod } from '@ampli/hooks';
import { useFetch } from '../hooks';
import {
  Helmet,
  BannerHomeCourse,
  AmpliProposal,
  WhyAmpli,
  CountStudents,
  BannerMobileApp,
  HomeTestimonials,
  CTAWhatsapp,
  DegreeCards,
  AppShell,
  MediaSocialProof,
  BlogSection,
} from '../components';
import { css, px2grid, mediaQuery } from '@ampli/ui';

import {
  SCROLLER_SETTINGS,
  CONTACT,
  AMPLI_BLACK_FRIDAY_START_DATE,
  AMPLI_BLACK_FRIDAY_END_DATE,
} from '../constants';
import {
  ampli,
  entranceExamUrl,
  recaptchaSiteKey,
  googleAPIKey,
  baseUrl,
  isLocal,
} from '../config';

import mockedTestimonialList from '../components/social-proof/testimonialList';

const Home = () => {
  const { pathname } = useLocation();
  const canonicalUrl = baseUrl + pathname;
  const subscriptionModal = useSubscriptionModal();
  const flagsResponse = useFetch('/flags.json');
  const { ref: homeRef, scroller: homeScroller } =
    useScroller(SCROLLER_SETTINGS);
  const { ref: degreeCardsRef, scroller: degreeCardsScroller } =
    useScroller(SCROLLER_SETTINGS);
  const { ref: contactRef, scroller: contactScroller } =
    useScroller(SCROLLER_SETTINGS);

  const { data: enrolledCandidatesCount } = useCountEnrolledCandidates();

  const defaultComponentBottomMargin = ({
    desktop = 150,
    mobile = 80,
  } = {}) => css`
    margin-bottom: ${px2grid(mobile)};

    ${mediaQuery('desktop')} {
      margin-bottom: ${px2grid(desktop)};
    }
  `;

  useEffect(() => {
    if (window.location.search === '?subscribe=true')
      subscriptionModal.show(true);
  }, []);

  const { isValidDate: isAmpliBlackFriday } = useCheckDateIsInPeriod(
    AMPLI_BLACK_FRIDAY_START_DATE,
    AMPLI_BLACK_FRIDAY_END_DATE,
    (isLocal ? 'http://localhost:7005' : baseUrl) + '/time'
  );

  return (
    <div ref={homeRef}>
      <Helmet
        title="Ampli: Graduação, Pós-Graduação, Cursos Livres e EJA Online"
        description="Conheça os cursos de Graduação, Pós-Graduação, Cursos Livres e EJA Online da Ampli. Estude pelo celular ou computador. Mensalidades Fixas. Inscreva-se!"
        robots="robots"
        canonical={canonicalUrl}
      />
      <AppShell
        headerConfig={{
          pageTopScroller: homeScroller,
          contactScroller: contactScroller,
          onSubscribeClick: subscriptionModal.show,
        }}
      >
        <BannerHomeCourse
          CTARedirectionURL="/graduacao"
          onClickScroll={degreeCardsScroller}
          isBlackFriday={isAmpliBlackFriday}
        />
        <div
          ref={degreeCardsRef}
          className={css`
            scroll-margin-top: ${px2grid(80)};
          `}
        >
          <DegreeCards
            className={css`
              ${defaultComponentBottomMargin()}
            `}
            isBlackFriday={isAmpliBlackFriday}
          />
        </div>
        <div
          className={css`
            ${defaultComponentBottomMargin()}
          `}
        >
          <AmpliProposal />
        </div>
        <MediaSocialProof
          className={css`
            ${defaultComponentBottomMargin()}
          `}
        />
        <WhyAmpli
          className={css`
            ${defaultComponentBottomMargin()}
          `}
        />
        <HomeTestimonials
          testimonials={mockedTestimonialList}
          className={css`
            ${defaultComponentBottomMargin({ desktop: 100 })}
          `}
        />
        <CountStudents
          count={enrolledCandidatesCount}
          redirectURL="/cursos"
          className={css`
            ${defaultComponentBottomMargin()}
          `}
        />
        <BlogSection pageType="home" />
        <BannerMobileApp
          onClick={subscriptionModal.show}
          className={css`
            ${defaultComponentBottomMargin({ desktop: 100 })}
          `}
        />
        <div ref={contactRef}>
          <CTAWhatsapp
            onClick={() => window?.open(CONTACT.WHATSAPP, '_blank')}
          />
        </div>
      </AppShell>
      <Modal
        {...subscriptionModal}
        isElementaryEjaEnable={flagsResponse?.isElementaryEjaEnable}
        isHighSchoolEjaEnable={flagsResponse?.isHighSchoolEjaEnable}
        isModal
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </div>
  );
};

export default Home;
