// should be COMMONS_JS
module.exports = {
  graphqlUrl: 'http://localhost:3000',
  subscriptionUrl: 'http://localhost:7001',
  entranceExamUrl: 'http://localhost:7002/processo-seletivo',
  baseUrl: 'http://localhost:7005',
  loginUrl: 'http://localhost:7004/login',
  followSubscriptionUrl: 'http://localhost:7002/processo-seletivo/login',
  env: 'local',
};
