import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["data", "loading"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from 'react';
import { sortBy } from '@ampli/utils';
import { useQuery } from '../hooks';
import { FIND_STATES, FIND_CITIES, GET_ADDRESS, FIND_ALL_COUNTRIES } from './queries';
export var useFindStates = function useFindStates(options) {
  var _useQuery = useQuery(FIND_STATES, options),
      loading = _useQuery.loading,
      data = _useQuery.data;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var states = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    states: states,
    state: state,
    setState: setState,
    loading: loading
  };
};
export var useFindCities = function useFindCities(stateId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery2 = useQuery(FIND_CITIES, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      stateId: stateId
    },
    skip: !stateId
  })),
      loading = _useQuery2.loading,
      data = _useQuery2.data;

  var _useState3 = useState({}),
      _useState4 = _slicedToArray(_useState3, 2),
      city = _useState4[0],
      setCity = _useState4[1];

  var cities = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    cities: cities,
    city: city,
    setCity: setCity,
    loading: loading
  };
};
export var useGetAddress = function useGetAddress(postalCode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery3 = useQuery(GET_ADDRESS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      postalCode: postalCode
    },
    skip: !postalCode
  })),
      data = _useQuery3.data,
      loading = _useQuery3.loading,
      rest = _objectWithoutProperties(_useQuery3, _excluded);

  var address = loading || !data ? null : data.data;
  return _objectSpread({
    data: address,
    loading: loading
  }, rest);
};
export var useFindAllCountries = function useFindAllCountries() {
  var _useQuery4 = useQuery(FIND_ALL_COUNTRIES),
      loading = _useQuery4.loading,
      data = _useQuery4.data;

  var countries = loading || !data ? [] : data.list;
  return {
    countries: countries,
    loading: loading
  };
};