import React, { useEffect, useState } from 'react';
import { cx } from '@ampli/ui';
import { useFetch } from '../../hooks';
import styles from './styles';

interface BlogCardProps {
  title: string;
  subtitle: string;
  link: string;
  image: string;
}
const BlogCard: React.FC<BlogCardProps> = ({
  title,
  subtitle,
  link,
  image,
}: BlogCardProps) => {
  const [imageHash, setImage] = useState();
  const response = useFetch(image);

  const getImageURL = (response: any) => {
    setImage(response?.guid?.rendered);
  };

  useEffect(() => {
    if (response) getImageURL(response);
  }, [response]);

  return (
    <div
      onClick={() => window.open(link)}
      className={cx('blog-card-paper', styles.paper)}
    >
      <img
        className={cx('blog-card-image', styles.courseImage)}
        src={imageHash}
        alt="Imagem que representa o post"
      />
      <p className={cx('blog-card-title', styles.title)}>{title}</p>
      <p
        className={cx('blog-card-description', styles.subtitle)}
        dangerouslySetInnerHTML={{
          __html: subtitle.replace('[&hellip;]', '').substr(3, 250) + ' [...]',
        }}
      />
    </div>
  );
};

export default BlogCard;
