import { css, getColor, mediaQuery, px2grid, getFontSize } from '@ampli/ui';

export default {
  paper: css`
    background: ${getColor('white')};
    border: 1px solid ${getColor('neutral-lighter')};
    box-sizing: border-box;
    box-shadow: ${px2grid(0)} ${px2grid(2)} ${px2grid(20)} rgba(0, 0, 0, 0.08);
    border-radius: ${px2grid(16)};
    padding: ${px2grid(15)};
    cursor: pointer;
    height: 100%;
    ${mediaQuery('mobile')} {
      margin-inline: ${px2grid(3)};
      min-width: ${px2grid(210)};
    }
  `,
  title: css`
    font-size: ${getFontSize('small')};
    color: ${getColor('brand-base')};
    text-transform: uppercase;
    margin: ${px2grid(0)};
    text-align: start;
    margin-top: ${px2grid(12.5)};
  `,
  subtitle: css`
    font-weight: bold;
    font-size: ${getFontSize('small')};
    margin: 0px;
    margin-top: ${px2grid(10)};
    color: ${getColor('neutral-dark')};
    text-align: start;
    ${mediaQuery('mobile')} {
      font-size: ${getFontSize('x-small')};
    }
  `,
  courseImage: css`
    width: 100%;
    object-fit: cover;
    height: 140px;
    ${mediaQuery('mobile')} {
      height: 120px;
    }
  `,
};
