import React, { FunctionComponent } from 'react';
import { cx, css, Text, px2grid, mediaQuery, Flex } from '@ampli/ui';
import { SocialProof, HighlightSocialProof } from './';
import mockedMediaTestimonialList from './highlight-social-proof/mockedTestimonials';
import { Testimonial } from './types';

interface MediaSocialProofProps {
  items?: Testimonial[];
  className?: string;
}

const MediaSocialProof: FunctionComponent<MediaSocialProofProps> = ({
  items = mockedMediaTestimonialList,
  className,
  ...props
}: MediaSocialProofProps) => (
  <Flex direction="column" alignItems="center" justifyContent="center">
    <div className={cx('media-social-proof', className)} {...props}>
      <Text
        className={css`
          font-weight: 100;
          margin-bottom: ${px2grid(30)};
          text-align: center;

          ${mediaQuery('desktop')} {
            margin-bottom: ${px2grid(64)};
          }
        `}
        color="brand-base"
        size={px2grid(64)}
      >
        Estamos na mídia
      </Text>
      <HighlightSocialProof
        items={items}
        className={css`
          ${mediaQuery('tablet-max')} {
            display: none;
          }
        `}
      />
      <div
        className={css`
          width: 100%;
        `}
      >
        <SocialProof
          testimonials={items}
          imageOnly
          className={css`
            padding-top: 0;

            .section-title {
              display: none;
            }

            ${mediaQuery('desktop')} {
              display: none;
            }
          `}
        />
      </div>
    </div>
  </Flex>
);

export default MediaSocialProof;
