import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import Home from '../pages/Home';
import { Polos } from '../pages/Polos';
import { UnifiedRedirect } from '../components';
import { generateSitemapByURLCode } from '../lib/sitemap-generation';

const courseListLoader = () =>
  import(/* webpackChunkName: "CourseList" */ '../pages/CourseList');

const generateSitemapForCourseDetails = (degree) => () =>
  generateSitemapByURLCode(degree);

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/cursos',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Courses" */ '../pages/Courses'),
      chunkName: 'Courses',
    }),
  },
  {
    path: '/graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/pos-graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/eja',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsUndergraduate" */ '../pages/CourseDetailsUndergraduate'
        ),
      chunkName: 'CourseDetailsUndergraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('UNDERGRADUATE'),
    },
  },
  {
    path: '/pos-graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsGraduate" */ '../pages/CourseDetailsGraduate'
        ),
      chunkName: 'CourseDetailsGraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('GRADUATE'),
    },
  },
  {
    path: '/eja/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsEJA" */ '../pages/CourseDetailsEJA'
        ),
      chunkName: 'CourseDetailsEJA',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('EJA'),
    },
  },
  {
    path: '/tim',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
    redirect: true,
  },
  {
    path: '/parceria',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
    redirect: true,
  },
  {
    path: '/parceria/tim',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Tim" */ '../pages/Tim'),
      chunkName: 'Tim',
    }),
  },
  {
    path: '/parceria/tim-vendedor',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "TimSeller" */ '../pages/TimSeller'),
      chunkName: 'TimSeller',
    }),
  },
  {
    path: '/polos-ead/:courseDegree',
    exact: true,
    component: asyncComponent({
      loader: () => import('../pages/PoloCourses'),
      chunkName: 'Polos',
    }),
    sitemap: {
      courseDegree: () => Promise.resolve(['eja', 'graduacao']),
    },
  },
  {
    path: '/polos-ead',
    exact: true,
    component: Polos,
  },
];
