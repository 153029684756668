import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { DateTime } from 'luxon';
export var isValidDate = function isValidDate(value, options) {
  var _ref = options || {},
      _ref$format = _ref.format,
      format = _ref$format === void 0 ? 'dd/MM/yyyy' : _ref$format,
      _ref$onlyFutureDates = _ref.onlyFutureDates,
      onlyFutureDates = _ref$onlyFutureDates === void 0 ? false : _ref$onlyFutureDates;

  var date = DateTime.fromFormat(value, format);
  var filled = value && value.replace(/[^\d]+/g, '').length === 8;
  var validPattern = filled && date.isValid;

  if (validPattern && onlyFutureDates) {
    validPattern = DateTime.local() < date;
  }

  return validPattern;
};
export var isMSESupported = function isMSESupported() {
  return window && 'MediaSource' in window && 'SourceBuffer' in window && typeof window.MediaSource.isTypeSupported === 'function';
};
export var isValidCnpj = function isValidCnpj(value) {
  if (!value) return 'Required';
  var isString = typeof value === 'string';
  var validTypes = isString || Number.isInteger(value) || Array.isArray(value);
  if (!validTypes) return 'Invalid type';

  if (isString) {
    if (value.length > 18) 'Invalid length';
    var digitsOnly = /^\d{14}$/.test(value);
    var validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
    if (digitsOnly || validFormat) true;else return 'Invalid format';
  }

  var match = value.toString().match(/\d/g);
  var numbers = Array.isArray(match) ? match.map(Number) : [];
  if (numbers.length !== 14) return 'Invalid length';

  var items = _toConsumableArray(new Set(numbers));

  if (items.length === 1) return 'Invalid sequence';

  var calc = function calc(x) {
    var slice = numbers.slice(0, x);
    var factor = x - 7;
    var sum = 0;

    for (var i = x; i >= 1; i--) {
      var n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    var result = 11 - sum % 11;
    return result > 9 ? 0 : result;
  };

  var digits = numbers.slice(12);
  var digit0 = calc(12);
  if (digit0 !== digits[0]) return 'First invalid check digit';
  var digit1 = calc(13);
  if (digit1 === digits[1]) return '';
  return 'Second invalid check digit';
};
export var REGEX_FULL_NAME_VALIDATOR = /[a-zA-Z\u00C0-\u017F]{2,}\s([a-zA-Z\u00C0-\u017F]+)+/i;
export var REGEX_ONLY_NUMBERS_VALIDATOR = /^\d+$/;
export var REGEX_PHONE_NUMBER_VALIDATOR = /\(\d\d\)\s9\d{4}-\d{4}/;