import { size, sumBy, calculateProgress } from '@ampli/utils';
import { pathUrlField } from './commons';
var progress = {
  read: function read(_, _ref) {
    var readField = _ref.readField;
    var learningObjectRefs = readField('learningObjects');
    var completedFromCache = sumBy(learningObjectRefs, function (learningObjectRef) {
      return readField('completed', learningObjectRef);
    });

    var _calculateProgress = calculateProgress(completedFromCache, size(learningObjectRefs)),
        quantity = _calculateProgress.quantity,
        completed = _calculateProgress.completed,
        percentage = _calculateProgress.percentage,
        percentageLabel = _calculateProgress.percentageLabel;

    return {
      quantity: quantity,
      completed: completed,
      percentage: percentage,
      percentageLabel: percentageLabel
    };
  }
};
export default {
  fields: {
    pathUrl: pathUrlField,
    progress: progress
  }
};