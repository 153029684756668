import React from 'react';

import {
  css,
  cx,
  px2rem,
  Dialog,
  DialogBackdrop,
  PrimaryButton,
  ColorBrandBase,
  GradientLinearBrand,
  px2grid,
} from '@ampli/ui';
import { object, func, arrayOf, shape, string } from '@ampli/utils';

import { HEADER_LINKS } from '../../constants';
import ButtonMobileMenu from './button-mobile-menu';

const DialogMenu = ({ items, dialogState, onLoginClick, onSubscribeClick }) => {
  const handleLoginClick = () => {
    if (!onLoginClick) return;

    dialogState.hide();
    onLoginClick();
  };

  const handleClick = (onClick) => {
    dialogState.hide();
    setTimeout(() => onClick && onClick(), 100);
  };

  const newItems = React.useMemo(
    () => [
      { label: 'início', href: '/' },
      { label: 'graduação', href: '/graduacao', target: '_self' },
      { label: 'pós-graduação', href: '/pos-graduacao', target: '_self' },
      {
        label: 'eja',
        href: '/eja',
      },
      {
        label: 'cursos livres',
        href: HEADER_LINKS['cursosLivres'],
      },
      {
        label: 'curso técnico',
        href: HEADER_LINKS['cursoTecnico'],
      },
      ...items.slice(2, 5),
      {
        label: 'Acompanhar inscrição',
        onClick: () => window?.open(HEADER_LINKS.followSubscription),
        color: ColorBrandBase,
        hasLetterSpacing: false,
      },
      {
        label: 'Sou aluno',
        onClick: handleLoginClick,
        hasLetterSpacing: false,
        color: ColorBrandBase,
      },
    ],
    [items]
  );

  return (
    <>
      <DialogBackdrop {...dialogState} />
      <Dialog
        {...dialogState}
        aria-label="menu"
        className={cx(
          'menu-mobile',
          css`
            background-color: white;
            display: flex;
            flex-direction: column;
            position: fixed;
            top: ${px2rem(60)};
            left: 0;
            right: 0;
            z-index: 10000;
            padding: ${px2rem(10)} ${px2rem(30)};
            padding-bottom: ${px2grid(20)};
            border-bottom-left-radius: ${px2rem(8)};
            border-bottom-right-radius: ${px2rem(8)};
            width: ${px2rem(291)};
            height: fit-content;
          `
        )}
        fullscreen
      >
        {newItems?.map((item, index) => (
          <ButtonMobileMenu
            key={`menu_mobile_item_${index}`}
            onClick={() => {
              window.location = item.href;
            }}
            hasBorder
            {...item}
            {...(item.onClick && { onClick: () => handleClick(item.onClick) })}
          >
            {item.label}
          </ButtonMobileMenu>
        ))}
        <PrimaryButton
          onClick={onSubscribeClick}
          as="strong"
          className={cx(
            'primary-button-navbar',
            css`
              font-weight: 400;
              background: ${GradientLinearBrand};
              border-color: transparent;
              margin-top: ${px2grid(12)};
            `
          )}
          size="small"
        >
          {'Experimente de graça'}
        </PrimaryButton>
      </Dialog>
    </>
  );
};

DialogMenu.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  dialogState: object,
  onLoginClick: func,
  onSubscribeClick: func,
};

export default DialogMenu;
