import { css, px2grid } from '@ampli/ui';

export default {
  wrapper: css`
    width: 100%;
    max-width: ${px2grid(1400)};

    & > .header {
      margin-bottom: ${px2grid(60)};
    }
  `,
  header: css`
    width: 100%;
    column-gap: ${px2grid(15)};
  `,
  headerElement: css`
    flex: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.inactive {
      filter: grayscale(1) opacity(0.5);
      transform: scale(0.8);
    }
  `,
  statement: css`
    flex: 1;
    width: 80%;
    min-height: ${px2grid(100)};
  `,
  statementText: css`
    flex: 0.8;
    transition: opacity 0.5s linear;

    &.inactive {
      opacity: 0;
    }

    &.active {
      opacity: 1;
    }
  `,
  quoteMark: css`
    width: ${px2grid(30)};
    transition: all 0.2s ease-in-out;
    margin: 0 ${px2grid(15)};

    &:nth-of-type(2n) {
      transform: rotate(180deg);
      align-self: flex-end;
    }
  `,
};
