import React, { forwardRef } from 'react';
import {
  css,
  cx,
  px2grid,
  mediaQuery,
  getColor,
  getSpaceSize,
  fontSize,
  Separator,
} from '@ampli/ui';
import { string, node, object, array, number, bool } from '@ampli/utils';

import Carousel from './carousel.tsx';
import StudentTestimonial from './student-testimonial';

const Wrap = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      'wrap',
      css`
        margin: 0 auto;
        max-width: ${px2grid(970)};
        padding: ${px2grid(84)} ${px2grid(16)} 0;
        width: 100%;

        ${mediaQuery('tablet')} {
          padding-left: ${px2grid(16)};
          padding-right: 0;
        }

        ${mediaQuery('desktop')} {
          padding-left: 0;
          padding-right: 0;
        }
      `,
      className
    )}
  />
);

Wrap.propTypes = {
  className: string,
};

const Section = forwardRef(
  ({ children, wrapProps = {}, className, ...props }, ref) => (
    <section {...props} ref={ref} className={cx('section', className)}>
      <Wrap {...wrapProps}>{children}</Wrap>
    </section>
  )
);

Section.propTypes = {
  children: node.isRequired,
  className: string,
  wrapProps: object,
};

Section.displayName = 'Section';

const TestimonialSection = ({ children, className, ...props }) => (
  <Section
    {...props}
    wrapProps={{
      className: cx(
        'testimonial-section-wrap',
        css`
          width: 100%;
          padding: 0;
          padding-top: ${px2grid(56)};

          ${mediaQuery('tablet')} {
            padding-left: 0;
            padding-right: 0;
          }

          ${mediaQuery('desktop')} {
            padding-top: ${px2grid(100)};
          }
        `,
        className
      ),
    }}
  >
    {children}
  </Section>
);

TestimonialSection.propTypes = {
  className: string,
  children: node.isRequired,
};

const SectionTestimonialTitle = ({ children, className, ...props }) => (
  <h2
    {...props}
    className={cx(
      'section-title',
      css`
        ${fontSize('xx-large')}
        text-align: center;
        font-weight: 400;
        color: ${getColor('neutral-title')};
        border-bottom: 1px solid ${getColor('neutral-lighter')};
        width: fit-content;
        padding-bottom: ${getSpaceSize('small')};
        margin: auto;
      `,
      className
    )}
  >
    {children}
  </h2>
);

SectionTestimonialTitle.propTypes = {
  className: string,
  children: node.isRequired,
};

const NewSeparator = ({ className, ...props }) => (
  <Separator
    {...props}
    className={cx(
      'new-separator',
      css`
        background: ${getColor('neutral-lighter')};
        border: 0;
        flex: none;
        height: 1px;
        width: ${px2grid(293)};
        margin: ${px2grid(16)} auto;

        ${mediaQuery('desktop')} {
          width: ${px2grid(721)};
        }
      `,
      className
    )}
  />
);

NewSeparator.propTypes = {
  className: string,
};

const CarouselWrap = ({ children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      'carousel-wrap',
      css`
        ${mediaQuery('desktop')} {
          height: 100%;
          width: 100%;
          position: relative;
          padding: 0;
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

CarouselWrap.propTypes = {
  className: string,
  children: node.isRequired,
};

const TestimonialCarousel = ({ testimonials, desktopItems = 2, imageOnly }) => {
  return (
    <Carousel responsive={{ desktop: { items: desktopItems } }}>
      {testimonials.map((testimonial) => (
        <StudentTestimonial
          key={testimonial.imgUrl}
          imgUrl={testimonial.imgUrl}
          name={testimonial.name}
          course={testimonial.course}
          testimonial={testimonial.testimonial}
          imageOnly={imageOnly}
        />
      ))}
    </Carousel>
  );
};

TestimonialCarousel.propTypes = {
  testimonials: array,
  desktopItems: number,
  imageOnly: bool,
};

const SocialProof = ({
  className,
  testimonials,
  desktopItems,
  imageOnly = false,
  ...props
}) => {
  return (
    <TestimonialSection {...props} className={className}>
      <SectionTestimonialTitle titleColor={getColor('neutral-title')}>
        O que nossos alunos dizem
      </SectionTestimonialTitle>
      {testimonials && testimonials?.length && (
        <CarouselWrap>
          <div className="carousel-main">
            <TestimonialCarousel
              imageOnly={imageOnly}
              testimonials={testimonials}
              desktopItems={desktopItems}
            />
          </div>
        </CarouselWrap>
      )}
    </TestimonialSection>
  );
};

SocialProof.propTypes = {
  className: string,
  desktopItems: number,
  imageOnly: bool,
  testimonials: array,
};

SocialProof.displayName = 'SocialProof';

export default SocialProof;
