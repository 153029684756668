import React from 'react';
import { func, node, object } from 'prop-types';
import {
  Header as DefaultHeader,
  Footer as DefaultFooter,
} from '../../components';

const AppShell = ({ children, header, footer, footerRef, headerConfig }) => {
  const Header = header ? header : DefaultHeader;
  const Footer = footer ? footer : DefaultFooter;
  return (
    <>
      <Header {...headerConfig} />
      {children}
      <Footer ref={footerRef} />
    </>
  );
};

AppShell.propTypes = {
  children: node,
  header: node,
  footer: node,
  headerConfig: object,
  footerRef: func,
};

export default AppShell;
