import React, { FunctionComponent } from 'react';
import { cx, css, px2grid } from '@ampli/ui';
import { string, bool } from '@ampli/utils';

import DegreeCard from './degree-card';
import styles from './styles';
import { DefaultComponentWithClassNameProps } from '../../types';

const EJAProps = {
  link: '/eja',
};

// TODO: Remove after BF
// TODO: use only DefaultComponentWithClassNameProps
interface CardWrapperProps extends DefaultComponentWithClassNameProps {
  isBlackFriday?: boolean;
}

const CardWrapper: FunctionComponent<CardWrapperProps> = ({
  className,
  isBlackFriday = false,
  ...props
}: CardWrapperProps) => {
  const blackFridayDegreeCardStyleConfig = css`
    .ampli-overlay-wrapper {
      filter: brightness(0%);
    }
  `;

  return (
    <div
      className={cx(
        'card-wrapper',
        styles.cardWrapper,
        className,
        isBlackFriday && blackFridayDegreeCardStyleConfig
      )}
      {...props}
    >
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="Parachute"
          title="EJA"
          subtitle="(antigo Supletivo)"
          {...EJAProps}
        >
          Educação para Jovens e Adultos, do ensino fundamental ao ensino médio.
        </DegreeCard>
        <DegreeCard
          illustration="RunningMan"
          title="Graduação"
          link="/graduacao"
          isBlackFriday={isBlackFriday}
        >
          Cursos bacharelados, licenciaturas e tecnólogos a distância.
        </DegreeCard>
        <DegreeCard
          illustration="RunningLady"
          title="Pós-"
          subtitle="Graduação"
          link="/pos-graduacao"
        >
          Cursos de especialização e MBA online.
        </DegreeCard>
      </div>
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="PlayingTennis"
          title="Cursos"
          subtitle="Livres"
          link="https://cursolivre.ampli.com.br/"
          externalLink
          className={cx(styles.degreeCardCourses)}
        >
          Cursos de curta duração com certificado.
        </DegreeCard>
        <DegreeCard
          illustration="RunningGirl"
          title="Curso"
          subtitle="Técnico"
          link="https://cogna.ubpages.com/ensino-tecnico/"
          externalLink
          className={cx(styles.degreeCardCourses)}
        >
          Cursos técnicos para entrar no mercado de trabalho em até 1 ano e
          meio.
        </DegreeCard>
      </div>
    </div>
  );
};

CardWrapper.propTypes = {
  className: string,
  isBlackFriday: bool,
};

export default CardWrapper;
