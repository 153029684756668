import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useEffect } from 'react';
import { INGRESS_MODALITY, AFFILIATE, SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE, DEGREE_TYPES } from "../../constants";
var validDegreeTypes = Object.keys(DEGREE_TYPES);

var checkIfFieldIsValidToShow = function checkIfFieldIsValidToShow(degreeType, field) {
  return validDegreeTypes.includes(degreeType) && SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE[degreeType].includes(field);
};

var useConditionallyShowFormFields = function useConditionallyShowFormFields(degreeType) {
  var _useState = useState(checkIfFieldIsValidToShow(degreeType, INGRESS_MODALITY)),
      _useState2 = _slicedToArray(_useState, 2),
      showIngressModalityOptions = _useState2[0],
      setShowIngressModalityOptions = _useState2[1];

  var _useState3 = useState(checkIfFieldIsValidToShow(degreeType, AFFILIATE)),
      _useState4 = _slicedToArray(_useState3, 2),
      showAffiliateSelectionOptions = _useState4[0],
      setShowAffiliateSelectionOptions = _useState4[1];

  useEffect(function () {
    setShowIngressModalityOptions(checkIfFieldIsValidToShow(degreeType, INGRESS_MODALITY));
    setShowAffiliateSelectionOptions(checkIfFieldIsValidToShow(degreeType, AFFILIATE));
  }, [degreeType]);
  return {
    showAffiliateSelectionOptions: showAffiliateSelectionOptions,
    showIngressModalityOptions: showIngressModalityOptions
  };
};

export default useConditionallyShowFormFields;