import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  cx,
  css,
  px2grid,
  getColor,
  getFontSize,
  mediaQuery,
  useDisclosureState,
  Disclosure,
  DisclosureContent,
} from '@ampli/ui';
import { ArrowDown } from '@ampli/icon';
import { DefaultComponentWithClassNameProps } from '../../types';
import { Category } from './types';
import { useBreakpoint } from '@ampli/hooks';
import { Link } from 'react-router-dom';

interface FooterSEOItemProps extends DefaultComponentWithClassNameProps {
  category: Category;
}

const FooterSEOItem: FunctionComponent<FooterSEOItemProps> = ({
  category,
  className,
}: FooterSEOItemProps) => {
  const [selected, setSelected] = useState(false);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const disclosure = useDisclosureState({ visible: isDesktop === true });
  const whiteColor = 'text-white';

  const handleToggle = () => {
    if (disclosure.visible) {
      setSelected && setSelected(false);
      disclosure.hide();
      return;
    }
    setSelected && setSelected(true);
    disclosure.show();
  };

  useEffect(() => {
    if (isDesktop) {
      disclosure.show();
    } else if (!selected && disclosure.visible) {
      disclosure.hide();
    }
  }, [selected, disclosure, isDesktop]);

  return (
    <div
      className={cx(
        'footer-seo-category',
        css`
          display: flex;
          flex-direction: column;

          padding: 0 ${px2grid(4)};

          p {
            font-size: ${getFontSize('base')};
            font-weight: bold;
            margin: 0;
          }

          a {
            color: ${getColor(whiteColor)};
            margin-left: ${px2grid(24)};
          }

          .disclosure-content {
            display: flex;
            flex-direction: column;

            margin: ${px2grid(8)} 0;
          }

          ${mediaQuery('desktop')} {
            a {
              margin-left: 0;
            }

            p {
              margin-bottom: ${px2grid(12)};
            }

            .collapsible-icon {
              display: none;
            }

            .disclosure-button {
              padding: 0;
              pointer-events: none;
            }

            .disclosure-content {
              margin: 0;
            }
          }
        `,
        className
      )}
    >
      <Disclosure
        {...disclosure}
        toggle={handleToggle}
        className={cx(
          'disclosure-button',
          css`
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: transparent;
            color: ${getColor(whiteColor)};
            border: none;
            padding: ${px2grid(16)} 0;
            margin: 0 auto;
            width: 100%;
          `
        )}
      >
        <p>{category.title}</p>
        <ArrowDown
          className={cx(
            'collapsible-icon',
            css`
              width: ${px2grid(12)};
              height: ${px2grid(12)};
              transform: rotate(0deg);
              margin-left: ${px2grid(12)};
              &.visible {
                transform: rotate(180deg);
              }
            `,
            disclosure.visible && 'visible'
          )}
          color={getColor(whiteColor)}
        />
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        className={cx(
          'disclosure-content',
          css`
            margin: ${px2grid(16)} auto ${px2grid(32)} auto;
            width: 100%;
          `
        )}
      >
        {category.items.map((item, index) =>
          item.isRelative ? (
            <Link to={item.url} key={index}>
              {item.label}
            </Link>
          ) : (
            <a href={item.url} key={index} target={item.target} rel={item.rel}>
              {item.label}
            </a>
          )
        )}
      </DisclosureContent>
      <div
        className={cx(
          css`
            ${mediaQuery('desktop')} {
              display: none;
            }
            position: relative;
            width: 100%;
            border-top: 1px solid ${getColor(whiteColor)};
            margin: ${px2grid(12)} 0;

            &.notVisible {
              margin: ${px2grid(4)} 0;
            }
          `,
          !disclosure.visible && 'notVisible'
        )}
      />
    </div>
  );
};

export default FooterSEOItem;
