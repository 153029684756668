import { useEffect, useState, useCallback } from 'react';
import { useFetch, UseGetPageURL } from '.';
import { every, map, propertyOf, identity } from '@ampli/utils';

interface BlogPostInterface {
  title: string;
  subTitle: string;
  postLink: string;
  imageURL: string;
}

interface UrlParamInterface {
  level?: string;
  course?: string;
}

interface BlogDataInterface {
  excerpt: { rendered: string };
  title: { rendered: string };
  link: string;
  _links: { ['wp:featuredmedia']: { [0]: { href: string } } };
}

interface DataItemInterface {
  id: number;
}

function useBlogResponse(pageType: string): BlogPostInterface[] {
  const [posts, setPosts] = useState<BlogPostInterface[]>([]);
  const response = useFetch('https://blog.ampli.com.br/wp-json/wp/v2/posts');
  const urlParam: UrlParamInterface | undefined = UseGetPageURL(
    typeof window !== 'undefined' ? window.location.href : '/',
    pageType
  );

  const setPostData = useCallback(
    (data: BlogDataInterface[]) => {
      const propsNeeded = [
        'title.rendered',
        'excerpt.rendered',
        'link',
        '_links.wp:featuredmedia.0.href',
      ];
      const posts = data
        .map((item: BlogDataInterface) => {
          return (
            item &&
            every(map(propsNeeded, propertyOf(item))) && {
              title: item.title.rendered,
              subTitle: item.excerpt.rendered,
              postLink: item.link,
              imageURL: item._links['wp:featuredmedia'][0].href,
            }
          );
        })
        .filter(identity);
      setPosts(posts);
    },
    [setPosts]
  );

  useEffect(() => {
    if (response && pageType === 'home') setPostData(response);
  }, [response, pageType, setPostData]);

  useEffect(() => {
    const getPostDataByTags = (tagId: number) => {
      fetch(`https://blog.ampli.com.br/wp-json/wp/v2/posts?tags=${tagId}`)
        .then((response) => response.json())
        .then((data) => setPostData(data))
        .catch((e) => console.error(e));
    };
    const getUserTagsIds = (userTagId: string) => {
      fetch(`https://blog.ampli.com.br/wp-json/wp/v2/tags?slug=${userTagId}`)
        .then((response) => response.json())
        .then((data) =>
          data.map((dataItem: DataItemInterface) =>
            getPostDataByTags(dataItem.id)
          )
        )
        .catch((e) => console.error(e));
    };

    if (pageType !== 'home' && urlParam) {
      if (urlParam['level'] !== '') getUserTagsIds(urlParam['level']);
      if (urlParam['course'] !== '') getUserTagsIds(urlParam['course']);
    }
  }, [urlParam, pageType, setPostData]);

  return posts;
}

export default useBlogResponse;
