import React from 'react';

import { getColor, cx, css, px2grid } from '@ampli/ui';
import { string, elementType, number } from '@ampli/utils';

const IconSelector = ({ icon, color, size = 24, className, ...props }) => {
  const SelectedIcon = icon;
  return (
    <SelectedIcon
      color={getColor(color)}
      currentColor={color && getColor(color)}
      {...props}
      className={cx(
        css`
          width: ${px2grid(size)};
          height: ${px2grid(size)};
        `,
        className
      )}
    />
  );
};

IconSelector.propTypes = {
  icon: elementType,
  color: string,
  size: number,
  className: string,
};

export default IconSelector;
