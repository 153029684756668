import React from 'react';
import {
  Flex,
  FlexItem,
  Text,
  css,
  cx,
  px2grid,
  mediaQuery,
  getColor,
} from '@ampli/ui';
import { string, array, number } from '@ampli/utils';

import Carousel from './carousel.tsx';
import HomeStudentTestimonial from './home-student-testimonial';

const Title = ({ className, ...props }) => (
  <Text
    as="h2"
    className={cx(
      'home-testimonials-title',
      css`
        display: flex;
        flex-direction: column;
        max-width: ${px2grid(230)};
        margin: 0 auto;
      `,
      className
    )}
    {...props}
  >
    <Text
      size="xxx-large"
      color="white"
      className={css`
        text-align: left;
        font-weight: bolder !important;
      `}
    >
      O que os 
    </Text>
    <Text
      className={css`
        text-align: right;
      `}
    >
      <Text
        size="huge"
        color="white"
        className={css`
          font-weight: 100 !important;
        `}
        block
      >
        nossos alunos{' '}
      </Text>
      <Text
        size="huge"
        color="white"
        className={css`
          font-weight: 100 !important;
        `}
      >
        dizem?
      </Text>
    </Text>
  </Text>
);
Title.propTypes = {
  className: string,
};

const Testimonials = ({
  testimonials,
  desktopItems = 1,
  className,
  ...props
}) => {
  function abbreviateToFirstNameAndInitials(name) {
    const splitName = name.split(' ');
    return `${splitName[0]} ${splitName[1][0]}.`;
  }

  return (
    <Carousel
      responsive={{ desktop: { items: desktopItems } }}
      className={cx(
        'testimonials-container',
        css`
          &.react-multi-carousel-list {
            background-color: transparent !important;

            .react-multiple-carousel__arrow {
              background: transparent;
              color: white;

              &:before {
                color: white;
              }
            }

            .react-multi-carousel-item {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          ${mediaQuery('mobile')} {
            & > .react-multi-carousel-track {
              align-items: flex-start;
            }
          }

          ${mediaQuery('tablet')} {
            max-width: ${px2grid(500)};
            margin: 0 auto;
          }
        `,
        className
      )}
      {...props}
    >
      {testimonials.map((testimonial) => (
        <HomeStudentTestimonial
          key={testimonial.imgUrl}
          imgUrl={testimonial.imgUrl}
          name={abbreviateToFirstNameAndInitials(testimonial.name)}
          course={testimonial.course}
          testimonial={testimonial.testimonial}
        />
      ))}
    </Carousel>
  );
};
Testimonials.propTypes = {
  className: string,
  testimonials: array,
  desktopItems: number,
};

const HomeTestimonials = ({ testimonials, className, ...props }) => (
  <div
    className={cx(
      'home-testimonials-wrapper',
      css`
        width: 100%;
        background: url(${require('../../assets/bg-depoimentos.jpg')});
        background-color: ${getColor('brand-base')};
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: cover;

        ${mediaQuery('tablet-max')} {
          background: url(${require('../../assets/bg-depoimentos-mobile.jpg')});
          background-color: ${getColor('brand-base')};
          background-position: 50% 0%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      `,
      className
    )}
    {...props}
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      matches={{ 'tablet-max': { direction: 'column' } }}
    >
      <div
        className={cx(
          'home-testimonials-content',
          css`
            max-width: ${px2grid(1000)};
            padding: ${px2grid(40)} 0;
            margin: 0 auto;
          `,
          className
        )}
      >
        <FlexItem flex={1}>
          <div
            className={cx(
              'title-wrapper',
              css`
                margin-bottom: ${px2grid(16)};
              `
            )}
          >
            <Title />
          </div>
        </FlexItem>
        <FlexItem flex={1}>
          <div className={cx('testimonials-wrapper')}>
            <Testimonials testimonials={testimonials} />
          </div>
        </FlexItem>
      </div>
    </Flex>
  </div>
);
HomeTestimonials.propTypes = {
  testimonials: array,
  className: string,
};

export default HomeTestimonials;
