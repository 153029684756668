import React from 'react';
import {
  cx,
  css,
  mediaQuery,
  Flex,
  Text,
  Paragraph,
  Button,
  getColor,
  getSpaceSize,
} from '@ampli/ui';
import { useFindAllLegalDocuments } from '@ampli/services';
import { string } from '@ampli/utils';
const textWhiteColor = getColor('text-white');

const LinkButton = ({ className, ...props }) => (
  <Button
    {...props}
    as="a"
    target="_blank"
    size="micro"
    className={cx(
      'link-button',
      css`
        border: 1px solid ${textWhiteColor};
      `,
      className
    )}
  />
);

LinkButton.propTypes = {
  className: string,
};

const LegalDocuments = ({ ...props }) => {
  const { data: legalDocumentsData, loading: legalDocumentsLoading } =
    useFindAllLegalDocuments();

  return (
    <>
      {!legalDocumentsLoading && legalDocumentsData?.data.length > 0 && (
        <Flex justifyContent="center" alignItems="center" direction="column">
          <div
            className={cx(
              'legal-documents',
              css`
                padding: ${getSpaceSize('xx-small')} 0 ${getSpaceSize('small')};

                ${mediaQuery('mobile')} {
                  padding-bottom: ${getSpaceSize('x-large')};
                }
              `
            )}
            {...props}
          >
            <Paragraph marginSize="xx-small">
              <Text color="text-white" size="small">
                Documentos legais
              </Text>
            </Paragraph>
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="center"
              wrap
              className={css`
                column-gap: ${getSpaceSize('x-small')};
                row-gap: ${getSpaceSize('x-small')};
              `}
            >
              <div>
                {legalDocumentsData.data.map((legalDocument, index) => (
                  <LinkButton href={legalDocument.url} key={index}>
                    <Text size="x-small" color="text-white">
                      {legalDocument.name}
                    </Text>
                  </LinkButton>
                ))}
              </div>
            </Flex>
          </div>
        </Flex>
      )}
    </>
  );
};

export default LegalDocuments;
