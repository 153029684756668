import React from 'react';
import { string, node, bool } from '@ampli/utils';
import {
  css,
  cx,
  px2rem,
  px2grid,
  mediaQuery,
  getColor,
  fontSize,
  Text,
  Paragraph,
  RichText,
} from '@ampli/ui';

const TestimonialName = ({ color, ...props }) => (
  <Paragraph
    {...props}
    className={cx(
      'item-title',
      css`
        text-align: center;
        color: ${getColor(color)};
        ${fontSize('small')}
        font-weight: bold;
        margin-bottom: 0;
      `,
      props.className
    )}
  />
);

TestimonialName.propTypes = {
  className: string,
  color: string,
};

const CourseName = ({ color, ...props }) => (
  <Paragraph
    {...props}
    className={cx(
      'item-title',
      css`
        text-align: center;
        color: ${getColor(color)};
        ${fontSize('small')}
        margin-bottom: ${px2rem(16)};
      `,
      props.className
    )}
  />
);

CourseName.propTypes = {
  className: string,
  color: string,
};

const StudentImage = ({ round = true, children, ...props }) => (
  <div
    {...props}
    className={cx(
      'student-image',
      css`
        margin-top: ${px2grid(40)};

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: ${px2grid(100)};
          height: auto;
        }

        &.round {
          img {
            border-radius: 50%;
            height: ${px2grid(60)};
            width: ${px2grid(60)};
          }
        }
      `,
      round && 'round',
      props.className
    )}
  >
    {children}
  </div>
);

StudentImage.propTypes = {
  className: string,
  round: bool,
  children: node.isRequired,
};

const TestimonialItem = ({ children, ...props }) => (
  <Text
    {...props}
    className={cx(
      'testimonial-item',
      css`
        ${fontSize('base')}
        font-weight: lighter;
        display: block;

        ${mediaQuery('desktop')} {
          width: ${px2grid(352)};
          padding: 0;
          display: inline;
        }
      `,
      props.className
    )}
  >
    <RichText>{children}</RichText>
  </Text>
);

TestimonialItem.propTypes = {
  className: string,
  children: node.isRequired,
};

const StudentTestimonial = ({
  imgUrl,
  name,
  course,
  testimonial,
  imageOnly,
}) => {
  return (
    <div
      className={css`
        padding-right: ${px2grid(20)};
        padding-left: ${px2grid(40)};

        ${mediaQuery('desktop')} {
          padding: 0;
        }
      `}
    >
      <StudentImage
        round={!imageOnly}
        className={css`
          margin-bottom: ${px2grid(imageOnly ? 40 : 8)};
        `}
      >
        <img
          alt="Estudante"
          src={imgUrl}
          width={100}
          height={100}
          loading="lazy"
        />
      </StudentImage>
      {!imageOnly && (
        <>
          <TestimonialName color={getColor('neutral-text')}>
            {name}
          </TestimonialName>

          {course && (
            <CourseName color={getColor('neutral-text')}>
              Estudante do curso de {course}
            </CourseName>
          )}
        </>
      )}

      <TestimonialItem>{testimonial}</TestimonialItem>
    </div>
  );
};

StudentTestimonial.propTypes = {
  imgUrl: string,
  name: string,
  course: string,
  testimonial: string,
  imageOnly: bool,
};

export default StudentTestimonial;
