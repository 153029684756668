import CNNLogo from '../assets/media/cnn-logo.webp';
import InfomoneyLogo from '../assets/media/infomoney-logo.webp';
import ExameInvestLogo from '../assets/media/exame-invest-logo.webp';
import ValorEconomicoLogo from '../assets/media/valor-economico-logo .webp';

export default [
  {
    imgUrl: CNNLogo,
    name: 'CNN',
    testimonial: `A Ampli é uma plataforma criada pela Cogna há aproximadamente um ano e que oferece cursos de graduação, pós-graduação e livres "ligados a profissões do futuro", afirmou o grupo de educação. A plataforma permite ingresso semanal de alunos e oferece cursos de menor duração, entre 18 meses e 36 meses, afirmou a Cogna.`,
  },
  {
    imgUrl: InfomoneyLogo,
    name: 'Infomoney',
    testimonial: `De acordo com o comunicado, haverá mais de 400 opções de cursos livres e mais de 250 de graduação e pós-graduação. Esta nova empresa usará a plataforma da Ampli, criada pela Kroton no ano passado com um investimento de R$ 240 milhões, e que tem 15 mil alunos matriculados.`,
  },
  {
    imgUrl: ExameInvestLogo,
    name: 'Exame Invest',
    testimonial: `O novo negócio será materializado por meio da startup Ampli, fundada pela Kroton em 2019 e que vai oferecer mais de 400 cursos livres e mais de 250 cursos de graduação e pós-graduação, em modalidade de ensino 100% digital.`,
  },
  {
    imgUrl: ValorEconomicoLogo,
    name: 'Valor Econômico',
    testimonial: `Kroton e TIM firmam parceria na startup de educação Ampli. A partir da parceria, a startup oferecerá cursos livres, de graduação e pós-graduação ligados às profissões do futuro, ministrados pelo celular.`,
  },
];
