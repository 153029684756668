import React, { FunctionComponent } from 'react';
import { cx, css, px2grid, px2rem, getColor, mediaQuery } from '@ampli/ui';
import { DefaultComponentWithClassNameProps } from '../../types';
import { Link } from 'react-router-dom';

interface StripProps extends DefaultComponentWithClassNameProps {
  stripWidthPx?: number;
  bgColor?: string;
  textColor?: string;
  repeatTextNTimes?: number;
  isVertical?: boolean;
  linkTo?: string;
  text: string;
}

const Strip: FunctionComponent<StripProps> = ({
  stripWidthPx = 30,
  bgColor = 'accent-base',
  textColor,
  repeatTextNTimes = 7,
  isVertical = true,
  text,
  linkTo,
  className,
}: StripProps) => {
  const spans = [...Array(repeatTextNTimes)].map((_, index) => (
    <span className="strip-text" key={index}>
      {text}
    </span>
  ));

  return (
    <div
      className={cx(
        'text-strip-wrap',
        css`
          background-color: ${getColor(bgColor)};
          color: ${textColor ? getColor(textColor) : 'black'};

          min-width: ${px2grid(84)};
          min-height: ${px2grid(stripWidthPx)};

          z-index: 1;

          display: flex;
          align-items: center;
          justify-content: space-between;

          font-weight: 700;
          white-space: nowrap;

          overflow: hidden;

          .strip-text {
            margin: ${px2rem(8)} 0;
          }

          &.isVertical {
            transform: rotate(180deg);
            min-height: ${px2grid(84)};
            min-width: ${px2grid(stripWidthPx)};

            writing-mode: vertical-lr;

            align-self: flex-start;
            margin-left: ${px2rem(8)};
            position: absolute;

            ${mediaQuery('tablet')} {
              margin-left: ${px2rem(16)};
            }

            ${mediaQuery('desktop')} {
              margin-left: ${px2rem(32)};
              align-self: auto;
              position: relative;
            }
          }

          &.linkTo > a {
            display: flex;
            justify-content: center;
            color: ${textColor ? getColor(textColor) : 'black'};
            text-decoration: none;
            width: 100%;
          }
        `,
        isVertical && 'isVertical',
        linkTo && 'linkTo',
        className
      )}
    >
      {linkTo ? (
        <Link to={linkTo}>
          {spans.map((item) => {
            return item;
          })}
        </Link>
      ) : (
        spans.map((item) => {
          return item;
        })
      )}
    </div>
  );
};

export default Strip;
