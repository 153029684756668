import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRef } from 'react';
import useIsInViewport from 'use-is-in-viewport';

var useScroller = function useScroller(_ref) {
  var _ref$isInViewportSett = _ref.isInViewportSettings,
      isInViewportSettings = _ref$isInViewportSett === void 0 ? {} : _ref$isInViewportSett,
      _ref$scrollIntoViewSe = _ref.scrollIntoViewSettings,
      scrollIntoViewSettings = _ref$scrollIntoViewSe === void 0 ? {} : _ref$scrollIntoViewSe;
  var scrollRef = useRef(null);

  var _useIsInViewport = useIsInViewport(_objectSpread(_objectSpread({}, isInViewportSettings), {}, {
    target: scrollRef
  })),
      _useIsInViewport2 = _slicedToArray(_useIsInViewport, 2),
      isInViewport = _useIsInViewport2[0],
      ref = _useIsInViewport2[1];

  var scroller = function scroller() {
    scrollRef.current.scrollIntoView(_objectSpread({
      behavior: 'smooth',
      block: 'start'
    }, scrollIntoViewSettings));
  };

  return {
    ref: ref,
    scroller: scroller,
    isInViewport: isInViewport,
    originalRef: scrollRef
  };
};

export default useScroller;