import { css, getColor, mediaQuery, getFontSize, px2grid } from '@ampli/ui';

export default {
  blogSection: css`
    margin-bottom: ${px2grid(100)};
  `,
  blogSectionTitle: css`
    font-size: ${getFontSize('xxx-large')};
    line-height: 100%;
    font-weight: normal;
    color: ${getColor('brand-base')};
    text-align: center;
    ${mediaQuery('mobile')} {
      text-align: start;
      font-size: ${getFontSize('xx-large')};
      width: 80%;
      margin-bottom: 8%;
      margin-left: 7%;
    }
  `,
  blogSectionCardsContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      ul li {
        padding-right: ${px2grid(45)} !important;
        padding-left: ${px2grid(45)} !important;
      }
      button {
        ${mediaQuery('mobile')} {
          display: none;
        }
      }
    }
  `,
  blogSectionDivider: css`
    height: 1px;
    background: ${getColor('neutral-lighter')};
    padding-inline: ${px2grid(10)};
    margin: ${px2grid(30)} ${px2grid(10)} ${px2grid(27)} ${px2grid(10)};
    ${mediaQuery('mobile')} {
      display: none;
    }
  `,
  blogSectionContainer: css`
    padding-inline: ${px2grid(100)};
    ${mediaQuery('mobile')} {
      padding-inline: ${px2grid(0)};
      overflow-x: scroll;
    }
  `,
};
